import React, { useEffect, useState } from "react";
import Header from "../../commonComponet/header";
import Copyright from "../../commonComponet/copyright";
import Phase1Comp from "./phase1Comp";
import Phase2Comp from "./phase2Comp";
import KycDocumentsComp from "./KYCDocumentsComp";
import { baseurl } from "../../commonComponet/apibaseurl";
import { Button, Modal } from 'antd';

function ProfilePage() {

  const [currentStep, setCurrentStep] = useState("phase1");
  const [isphase2Status, setPhase2Status] = useState("");
  const [isanswer, setIsanswer] = useState("");
  const [isprofileansweredit, setIsprofileansweredit] = useState();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [message, setMessage] = useState();
  const [targetStep, setTargetStep] = useState("");

  const [error, setError] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const showModal = () => {
    setOpen(true);
  };

  const showModal2 = () => {
    setOpen2(true);
  };

  const handleOk = () => {
    setOpen(false);
    setCurrentStep(targetStep);
    setOpen2(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  function pageNavigate(step) {
    if (currentStep === "phase2" && step !== "phase2") {
      setTargetStep(step);
      isprofileansweredit===true?showModal(): setCurrentStep(step);
      return;
    }
    setCurrentStep(step);
  }

  const [isData, setIsdata] = useState(null);

  function fetchData() {
    const token = localStorage.getItem("token");
    console.log(token);

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    fetch(`${baseurl}/api/customer-profile/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      } else {
        return response.json();
      }
    })
    .then(data => {
      if (data.success === '4') {
        window.location.href = "/";
      }
      if (data.success === '1') {
        setIsdata(data);
        setPhase2Status(data.phase_2_status);
        console.log("phase_1", data.phase_1_status);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  function teating(e) {
    setIsanswer(e);
  }

  function isanswerEdit(e){
    // console.log(e)
    setIsprofileansweredit(e)
  }

  const submitFunction = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    const payload = {
      answers: isanswer,
      phase_2_status: "Pending"
    };

    const jsonData = JSON.stringify(payload);
    fetch(`${baseurl}/api/submit-phase-2-answers/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: jsonData
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Success:', data);

        setMessage(data.message);
        if (data.success === '4') {
          window.location.href = "/";
        }
        if (data.success === '1') {
          setSubmissionStatus('success');
          showModal2();
        } else {
          setSubmissionStatus('error');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setSubmissionStatus('error');
      });
  };

  return (
    <>
      <Header onClickpageNavigate={pageNavigate} phase2Status={isphase2Status} getSetction={currentStep} headeranswer={isanswer} headerisEditAnswer={isprofileansweredit}  />
      {isData === null ?
        <div className="profile_page" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={{ height: "100vh", width: "100%", display: "flex", alignItems: "center" }}>
            <h3>loading</h3>
          </div>
        </div> :
        <section className="profile_page">
          <div className="profile_left_container">
            <div className="profile_image_container">
              <img src={isData.data.live_photo === null ? "https://img.freepik.com/free-photo/adorable-smiling-girl-standing-front-tree-park_23-2148163934.jpg?size=626&ext=jpg" : isData.data.live_photo} height="100%" width="100%" alt="loading" style={{ objectFit: "cover" }} />
            </div>
              <div className="profile_name_container">
                 <h2 className="user_name">{isData.data.first_name}</h2>
                 <h2 className="user_name">&nbsp;{isData.data.last_name}</h2>
              </div>
            <nav className="profile_navbar">
              <button className="profile_button" onClick={() => pageNavigate("phase1")}>Section 1</button>
              <button className="profile_button" onClick={() => pageNavigate("phase2")}>Section 2</button>
              <button className="profile_button" onClick={() => pageNavigate("kycdocumets")}>KYC Documents</button>
            </nav>
          </div>
          <div className="profile_right_container">
            <div className="inner_profile_right_container">
              {currentStep === "phase1" && (
                <Phase1Comp
                  other_images={isData.other_images}
                  email={isData.data.email} mobile_number={isData.data.mobile_number} date_of_birth={isData.data.date_of_birth} gender={isData.data.gender}
                  address_line_1={isData.data.address_line_1} address_line_2={isData.data.address_line_2} city={isData.data.city} country={isData.data.country} postal_code={isData.data.postal_code} father_name={isData.data.father_name}
                  mother_name={isData.data.mother_name} sibling_count={isData.data.sibling_count} education={isData.data.education} current_profession={isData.data.current_profession} company={isData.data.company} designation={isData.data.designation}
                  other_professional_details={isData.data.other_professional_details} height_feet={isData.data.height_feet} height_inches={isData.data.height_inches} religion={isData.data.religion} relationship_status={isData.data.relationship_status}
                  further_comments={isData.data.further_comments} heard_from={isData.data.heard_from} live_photo={isData.data.live_photo} education2={isData.data.education2} total_age={isData.data.total_age} country_code={isData.data.country_code} 
                />)}
              {currentStep === "phase2" && (
                <Phase2Comp instructions={isData.instructions} checkkycstatus={isData.data.kyc_status} phase2status={isData.phase_2_status} gotophase1={setCurrentStep} teatingprops={teating} isanswerEditprops={isanswerEdit} />
              )}
              {currentStep === "kycdocumets" && (<KycDocumentsComp />)}
            </div>
          </div>
        </section>
      }
      <Copyright style={{ position: "absolute", bottom: "0px" }} />

      <Modal
        open={open}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={submitFunction} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >Yes</Button>
            <Button onClick={handleOk} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >No</Button>
            <Button onClick={handleCancel} style={{backgroundColor:"#000",color:"#fff",border:"none"}} >Cancel</Button>
          </>
        )}
      >
        <br />
        <h4>Do you want to save the changes?</h4>
        <br />
        {/* <Button onClick={submitFunction} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >Yes</Button>
        <Button onClick={handleOk} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >No</Button>
        <Button onClick={handleCancel} style={{backgroundColor:"#000",color:"#fff",border:"none"}} >Cancel</Button> */}
      </Modal> 

      <Modal
        open={open2}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={handleOk} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >Ok</Button>
          </>
        )}
      >
        <br />
        <h4>Changes are saved successfully.</h4>
        <br />
        {/* <Button onClick={handleOk} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >Ok</Button> */}
      </Modal> 
    </>
  );
}

export default ProfilePage;


