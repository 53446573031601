import React, { useEffect, useState } from "react";
import StaticExample from "../../commonComponet/phase2Modal";
import { Button, Modal } from 'antd';
import QuestionComp from "./Componets/questionsComp";
import QuestionRead from "./Componets/questionsRead";

function Phase2Comp({checkkycstatus,gotophase1,phase2status,instructions,submitFunction2,teatingprops,isanswerEditprops}){
    // console.log("phase2status", phase2status)
    const [isModalOpen, setIsModalOpen] = useState(false);
      
useEffect(()=>{
    setIsModalOpen(true);
},[])

    // const showModal = () => {
    //   setIsModalOpen(true);
    // };
  
    const handleOk = () => {
      setIsModalOpen(false);
      gotophase1("phase1")
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
      gotophase1("phase1")
    };

    function teatingpropsphase2(e){
      teatingprops(e)
    }

    function isanswerEditprops2(e){
      isanswerEditprops(e) 
    }
    

    return(
        <>
        {
            phase2status==="Pending"?<QuestionComp teatingprops={teatingpropsphase2} isanswerEdit={isanswerEditprops2} />:<QuestionRead instructions={instructions} />
        }
        </>
    );
    
}
export default Phase2Comp;