import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Copyright from './commonComponet/copyright';
import Header from './commonComponet/header';
import { ImageProvider } from './formsteps/imageProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ImageProvider>
    <App />
    </ImageProvider>
  // </React.StrictMode>
);



reportWebVitals();
