import React, { useRef } from "react";
import {message, } from 'antd';

function Moreaboutyou({value,onClick,setFormData,phase1statusValue,submitfunctionNext}){
    const {height_feet,height_inches,religion,relationship_status,further_comments,} = value;

    const heightFeetRef = useRef(null);
    const heightInchesRef = useRef(null);
    const religionRef = useRef(null);
    const relationshipStatusRef = useRef(null);
    const furtherCommentsRef = useRef(null);

    const [messageApi, contextHolder] = message.useMessage();

    const handleStepChange = (e) => {
        // phase1statusValue("Incomplete");
        submitfunctionNext();
        e.preventDefault();
        const heightFeetValue = heightFeetRef.current.value.trim();
        const heightInchesValue = heightInchesRef.current.value.trim();
        const religionValue = religionRef.current.value.trim();
        const relationshipStatusValue = relationshipStatusRef.current.value.trim();
        const furtherCommentsValue = furtherCommentsRef.current.value.trim();

        

        if (heightFeetValue === "") {
            messageApi.open({type: 'error', content: 'Height (ft) is required!'});
            return;
        }
        
        if (heightInchesValue === "") {
            messageApi.open({type: 'error', content: 'Height (inch) is required!'});
            return;
        }
        
        if (religionValue === "") {
            messageApi.open({type: 'error', content: 'Religion is required!'});
            return;
        }
        
        if (relationshipStatusValue === "") {
            messageApi.open({type: 'error', content: 'Relationship status is required!'});
            return;
        }
        
        
        setFormData((prevData) => ({
            ...prevData,
            height_feet: heightFeetValue,
            height_inches: heightInchesValue,
            religion: religionValue.charAt(0).toUpperCase() + religionValue.slice(1),
            relationship_status: relationshipStatusValue.charAt(0).toUpperCase() + relationshipStatusValue.slice(1),
            further_comments: furtherCommentsValue.charAt(0).toUpperCase() + furtherCommentsValue.slice(1)
        }));
        onClick("yourphotograph");
    };

    const handleStepChangeback = (e) => {
        e.preventDefault();
        onClick("professional");
      };
      const handleChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'religion') && !/^[a-zA-Z," "]*$/.test(value)) {
            messageApi.open({ type: 'error', content: 'Only letters are allowed!' });
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };     
    return(
        <>
            <div className="inner_loginform_container">
                <div className="title">More About You</div>
                   {contextHolder}
                    <div className="height_input_feild_container">
                       <div>
                          <label htmlFor="username">Height*</label>
                            <select className="select_height"
                             name="height_feet" 
                             id="height_feet"
                             value={height_feet}
                             ref={heightFeetRef}
                             onChange={handleChange} 
                             >
                                <option selected value="">Select ft</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option  value="4">4</option>
                                <option  value="5">5</option>
                                 <option value="6">6</option>
                                 <option value="7">7</option>
                            </select>
                       </div>
                       <div>
                          <label htmlFor="" style={{height:"1rem"}}></label>
                            <select className="select_height"
                             name="height_inches" 
                             id="height_inches"
                             value={height_inches}
                             ref={heightInchesRef}
                             onChange={handleChange}
                             >
                            <option selected value="">Select inch</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option  value="4">4</option>
                                <option  value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                {/* <option value="12">12</option> */}
                            </select>
                       </div>
                    </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="password">Religion*</label>
                    <input
                        className="inpufeild custom_capitalize"
                        type="text"
                        id="religion"
                        name="religion"
                        value={religion}
                        ref={religionRef}
                        onChange={handleChange}
                        placeholder="Enter your religion"
                        maxLength="250"
                    />
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="relationship_status">Relationship status*</label>
                    <select className="inpufeild"
                        id="relationship_status"
                        name="relationship_status"
                        value={relationship_status}
                        ref={relationshipStatusRef}
                        onChange={handleChange}                   
                    >
                        <option value=""selected >Select</option>
                        <option value="SINGLE" >SINGLE</option>
                        <option value="DIVORCED" >DIVORCED</option>
                        <option value="ENGAGED ONCE" >ENGAGED ONCE</option>
                        <option value="WIDOWED" >WIDOWED</option>
                        <option value="OTHER " >OTHER </option>
                    </select>
                </div>
   
                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="further_comments">Details of Parents and Siblings ( Maximum 350 characters )</label>
                    <textarea className="textarea_class"
                        maxLength="350"
                        name="further_comments"
                        id="further_comments"
                        value={further_comments}
                        ref={furtherCommentsRef}
                        onChange={handleChange}                    
                     style={{width:"70%", height:"100px", marginTop:"1rem", padding:"1rem", resize:"none"}} placeholder="Please add any information which you feel is important to share with us.">
                    </textarea>
                </div>


                <div className="btn_container">
                  <button onClick={handleStepChangeback} className="loginBtnBack" type="submit">
                   Back
                </button>

                <button onClick={handleStepChange} className="loginBtn" type="submit">
                    Next Step
                </button>
</div>
                <br /><br />
            </div>
        </>
    );
}
export default Moreaboutyou;