// import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
// import { baseurl } from "./apibaseurl";

// function Header2(){

//   const [isdropdownhide, setIsdropdownhide] = useState(true);
//   const [error, setError] = useState("");


//   function dropdownFuntion(){
//     isdropdownhide === true? setIsdropdownhide(false):setIsdropdownhide(true)
//   }


//   const handlelogout= async () => {
//     const token = localStorage.getItem("token");
//     console.log(token)


//     try {
//         const response = await fetch(`${baseurl}/api/logout/`, {
//           method: 'GET',
//             headers: {
//                 "Content-Type": "application/json",
//                 'Authorization': `Token ${token}`
//             },
//         });   
//         console.log(response);     
//         if (response.ok) {
//           window.location.href = "/"
//         }else{
//           throw new Error('Network response was not ok');
//         }
       
        
//     } catch (error) {
//         setError("An error occurred. Please try again later.");
//     }
// };  

//     return(
//         <>
//          <div className="header_container">
//          <nav className="header_nav">
//           <div className="menu_container" >
//             <div className="logo_container"></div>

//           <div onClick={dropdownFuntion} >
//             {
//               isdropdownhide===true? 
//                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="currentColor" d="M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z"/></svg>
//                :
//                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="currentColor" d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z"/></svg> 
//              }
//             </div>
//           </div>
//         </nav>

//         <div className="header_dropdown" style={{height:"80px",}} hidden={isdropdownhide} >
//           <div className="dropdown_menu_container" onClick={handlelogout} style={{cursor:"pointer"}}>
//           <h6>Logout</h6>
//            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style={{color:"#C6222F", marginTop:"-0.5rem"}} viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 6.5C4.159 8.148 3 10.334 3 13a9 9 0 1 0 18 0c0-2.666-1.159-4.852-3-6.5M12 2v9m0-9c-.7 0-2.008 1.994-2.5 2.5M12 2c.7 0 2.008 1.994 2.5 2.5" color="currentColor"/></svg>
//           </div>
//         </div>

//          </div>
         
//         </>
//     )
// }

// export default Header2;





import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { baseurl } from "./apibaseurl";

function Header2() {
  const [isdropdownhide, setIsdropdownhide] = useState(true);
  const [error, setError] = useState("");
  const dropdownRef = useRef(null);
  const dropdownToggleRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownToggleRef.current &&
        dropdownRef.current &&
        !dropdownToggleRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsdropdownhide(true);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownFuntion = () => {
    setIsdropdownhide(prevState => !prevState);
  };

  const handlelogout = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${baseurl}/api/logout/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      console.log(response);
      if (response.ok) {
        window.location.href = "/";
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="header_container">
        <nav className="header_nav">
          <div className="menu_container">
            <div className="logo_container"></div>

            <div  ref={dropdownToggleRef}  onClick={dropdownFuntion}>
              {isdropdownhide === true ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z"
                  />
                </svg>
              )}
            </div>
          </div>
        </nav>

        <div
          ref={dropdownRef}
          className="header_dropdown"
          style={{ height: "80px" }}
          hidden={isdropdownhide}
        >
          <div
            className="dropdown_menu_container"
            onClick={handlelogout}
            style={{ cursor: "pointer" }}
          >
            <h6>Logout</h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              style={{ color: "#C6222F", marginTop: "-0.5rem" }}
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6 6.5C4.159 8.148 3 10.334 3 13a9 9 0 1 0 18 0c0-2.666-1.159-4.852-3-6.5M12 2v9m0-9c-.7 0-2.008 1.994-2.5 2.5M12 2c.7 0 2.008 1.994 2.5 2.5"
                color="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header2;
