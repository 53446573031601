import React from "react";

function Phase1Comp({email,mobile_number,date_of_birth,gender,
    address_line_1,address_line_2,city,country,postal_code,father_name,
    mother_name,sibling_count,education,current_profession,company,designation,
    other_professional_details,height_feet,height_inches,religion,relationship_status,
    further_comments,heard_from,live_photo,other_images,education2,total_age,country_code
    }){
      


        function formatDate(dateString) {
            const dateParts = dateString.split('-');
            if (dateParts.length === 3) {
              const [year, month, day] = dateParts;
              return `${day}-${month}-${year}`;
            } else {
              // Handle invalid date format gracefully
              return 'Invalid Date';
            }
          }
          

    return(
<div>
        <div className="personal_info_container">
        <div className="inner_personal_info_container">
        <h4>Personal Information</h4>
        <div className="personal_content">
          <div>
              <div>Date of birth</div>
              {/* <div>{date_of_birth}</div> */}
              <div style={{color:"#000"}}>{formatDate(date_of_birth)}</div>
              <div style={{display:"flex",alignItems:"center", gap:"1rem"}}>
              <div>Age</div>
              <div><span style={{color:"#000"}}>{total_age}&nbsp; Years</span></div>
              </div>

              <div>Email</div>
              <div style={{color:"#000"}} >{email}</div>
          </div>
          <div>
              <div>Gender</div>
              <div className="custom_capitalize" style={{color:"#000"}}>{gender}</div>
              <div>Mobile</div>
              <div style={{color:"#000"}} >{country_code}&nbsp;{mobile_number}</div>
          </div>
        </div>
        </div>
        </div>
        
        <div className="personal_info_container">
        <div className="inner_personal_info_container">
        <h4>Residential Information</h4>
        <div className="personal_content">
          <div style={{width:"100%"}}>
              <div>Address</div>
              <div className="custom_capitalize"style={{color:"#000", display:"flex", flexWrap:"wrap",width:"100%"}} >
                 <div>{address_line_1}&nbsp;&nbsp;</div>
                 <div>{address_line_2}&nbsp;{postal_code}</div>
              </div>
          </div>
        </div>
        </div>
        </div>
        
        <div className="personal_info_container">
        <div className="inner_personal_info_container">
        <h4>Family Information</h4>
        <div className="personal_content">
          <div>
              <div>Father's name</div>
              <div className="custom_capitalize" style={{color:"#000"}} >{father_name}</div>
              <div>Number of siblings</div>
              <div style={{color:"#000"}}>{sibling_count}</div>
          </div>
          <div>
              <div>Mother's name</div>
              <div className="custom_capitalize"style={{color:"#000"}} >{mother_name}</div>
          </div>
        </div>
        </div>
        </div>
        
        <div className="personal_info_container">
        <div className="inner_personal_info_container">
        <h4>Professional Information</h4>
        <div className="personal_content personal_content_professinal">
          <div>
              <div>Education</div>
              <div style={{color:"#000"}}>{education}</div>
              <div style={{color:"#000"}}>{education2}</div>
              <div>Name of company</div>
              <div style={{color:"#000"}}>{company}</div>
              <div>Designation</div>
              <div style={{color:"#000"}}>{designation}</div>
          </div>
          <div>
              <div>Current profession</div>
              <div className="custom_capitalize" style={{color:"#000"}} >{current_profession}</div>
              <div>Other professional details</div>
              <div className="custom_capitalize" style={{color:"#000"}} >{other_professional_details}</div>
          </div>
        </div>
        </div>
        </div>

        <div className="personal_info_container">
        <div className="inner_personal_info_container">
        <h4>More About You</h4>
        <div className="personal_content personal_content_professinal">
          <div>
              <div>Height</div>
              <div style={{color:"#000"}}>{height_feet}.{height_inches}</div>
              {/* <div>Height inches</div>
              <div>{height_inches}</div> */}
              <div>Religion</div>
              <div style={{color:"#000"}}>{religion}</div>
          </div>
          <div>
              <div>Relationship status</div>
                 <div style={{color:"#000"}}>{relationship_status}</div>
              <div>Further comments</div>
              <div style={{color:"#000"}}>{further_comments}</div>
                  <div>How did you hear about us</div>
              <div style={{color:"#000"}}>{heard_from}</div>
          </div>

        </div>
        </div>
        </div>

        <div className="other_images_container">
            {
                other_images.map(items=>{
                    return(
                        <>
                       <div className="inner_other_images_container" >
                          <img src={items.other_images} alt="loading" />
                       </div> 
                        </>
                    ) ;
                })
            }
          </div>

        </div>
    );
}
export default Phase1Comp;





