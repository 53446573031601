import React, { useEffect } from 'react';
import { message } from 'antd';
import { useImageContext } from './imageProvider';
import LivePhotoCapture from '../subcomponets/livecapturephoto';
import BrousePhoto from '../subcomponets/brouwsephoto';

function Yourphotograph({ value, onClick, updatLivePhotoPropsForms, uptBrouseImageFormProps, phase1statusValue, submitfunctionNext,isDataLivePhotoProps,testing1,isDataOtherPhotoprops,refreshOtherimages }) {
  const { livePhoto, setLivePhoto, brouseImages, setBrouseImages } = useImageContext();
  const { live_photo, other_images } = value;


  const [messageApi, contextHolder] = message.useMessage();


  
  useEffect(() => {
    setLivePhoto(live_photo);
    setBrouseImages(other_images);
  }, [live_photo, other_images, setLivePhoto, setBrouseImages]);

  const handleStepChange = (e) => {
    e.preventDefault();
    if (!livePhoto) {
      messageApi.error('Live photo is required!');
      return;
    }
    if (brouseImages.length === 0) {
      messageApi.error('At least one other image is required!');
      return;
    }
    // phase1statusValue("Incomplete");
    submitfunctionNext();
    onClick("submit");
  };

  const handleStepChangeback = (e) => {
    e.preventDefault();
    onClick("moreaboutyou");
  };

  function updatLivePhoto(e) {
    setLivePhoto(e);
    updatLivePhotoPropsForms(e===""?null:e);
  }

  function uptBrouseImage(e) {
    uptBrouseImageFormProps(e);
  }

  async function testing(){
    navigator.permissions.query({ name: "camera" }).then(res => {
        if(res.state === "granted"){
            // has permission
            console.log("granted");
        }else{
            console.log("not granted"); 
            messageApi.open({ type: 'error', content: ' Camera permission is not granted' }); 
        }
    })
}

// testing();


  return (
    <>
      {contextHolder}
      <div className="inner_loginform_container">
        <div className="title">Photograph</div>

        <div className='photograph_title' style={{ display: "flex", alignItems: "center", gap: "3rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className='userphoto'></div>
            <label htmlFor="password">Live Photo*</label>
          </div>
          <label htmlFor="password">Click to open camera from your device.</label>
        </div>
        <div style={{ width: "70%", margin: "auto" }}>
          <LivePhotoCapture testing1={testing1} updatLivePhotoProps={updatLivePhoto} livePhotoUrl={live_photo}  setImageurl={setLivePhoto} initialPhoto={livePhoto} testingProps={testing} livePhotoProps2={isDataLivePhotoProps} />
        </div>
        <br />

        <div className='photograph_title' style={{ display: "flex", alignItems: "center", gap: "3rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className='userphoto'></div>
            <label htmlFor="password">Other Images*</label>
          </div>
          <label htmlFor="password">Please feel free to add up to 3 images of yourself.</label>
        </div>
        <BrousePhoto refreshOtherimagesprops={refreshOtherimages} uptBrouseImageProps={uptBrouseImage} setbrouseimage={setBrouseImages} isDataOtherPhotoprops={isDataOtherPhotoprops} />

        <div className="btn_container">
          <button onClick={handleStepChangeback} className="loginBtnBack" type="submit">Back</button>
          <button onClick={handleStepChange} className="loginBtn" type="submit">Next step</button>
        </div>
      </div>
    </>
  );
}

export default Yourphotograph;
