import React, { useEffect, useState } from "react";
import { baseurl } from "../../../commonComponet/apibaseurl";
import { Button, Modal } from 'antd';

function QuestionComp({teatingprops,isanswerEdit}) {
    const [isData, setIsData] = useState([]);
    const [isdatasubmit, setIsDatasubmit] = useState(null);
    const [answer, setAnswer] = useState([]);
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [message, setMessege] = useState();
    const [iseditanswer, setIseditanswer] =useState(false);



    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const showModal = () => {
        setOpen(true);
      };
      const showModal2 = () => {
        setOpen2(true);
      };

      const handleCancel = () => {
        setOpen(false);
      };

    function navigatetoprofile(){
        window.location.href = "/profile"
    }  


    function fetchData() {
        const token = localStorage.getItem("token");

        if (!token) {
            console.error("Token not found in localStorage");
            return;
        }

        fetch(`${baseurl}/api/list-phase-2-questions/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.success === '4') {
                window.location.href = "/"
            }
            if (data.success === '1') {
                setIsData(data.data);
                setIsDatasubmit(data.message);
                const initialAnswers = data.data.map(item => ({ id: item.id, answer: item.answer || '' }));
                setAnswer(initialAnswers);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }



    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (e) => {
        setIseditanswer(true);
        const { id, value } = e.target;
        setAnswer((prevData) => {
            const existingAnswerIndex = prevData.findIndex(item => item.id === parseInt(id));
            if (existingAnswerIndex >= 0) {
                // Update existing answer
                const updatedData = [...prevData];
                updatedData[existingAnswerIndex].answer = value;
                return updatedData;
            } else {
                // Add new answer
                return [...prevData, { id: parseInt(id), answer: value }];
            }
        });
    };



    function submitFunction(status) {
        // console.log(status);
        const token = localStorage.getItem("token");

        if (!token) {
            console.error("Token not found in localStorage");
            return;
        }

        const payload = {
            answers: answer,
            phase_2_status: status
        };

        const jsonData = JSON.stringify(payload);
        fetch(`${baseurl}/api/submit-phase-2-answers/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: jsonData
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            
            setMessege(data.message);
            if (data.success === '4') {
                window.location.href = "/"
            }
            if (data.success === '1') {
                setSubmissionStatus('success');
                setOpen(false)
                showModal2()
                // window.location.href = "/profile"
            } else {
                setSubmissionStatus('error');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setSubmissionStatus('error');
        });
    }

    // console.log("iseditanswer",iseditanswer);

    useEffect(()=>{
        teatingprops(answer)
        isanswerEdit(iseditanswer)
    })

// console.log(isData)
    return (
        <>
            <div className="shadow" style={{ width: "90%", margin: "auto", padding: "2rem", backgroundColor: "#fff" }}>
                {isData === null ? "": isData.map((items) => {
                    const currentAnswer = answer.find(a => a.id === items.id)?.answer || '';

                    localStorage.setItem("answertesting",currentAnswer);

                    return (
                        <div key={items.id}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <h6 style={{ fontWeight: "500", color:"#a5a4a4" }}>{items.id}. &nbsp;{items.question}</h6>
                            </div>
                            <textarea
                            //  style={{ fontWeight: "500" }}
                                value={currentAnswer}
                                name={`answer${items.id}`}
                                id={items.id.toString()}
                                onChange={handleChange}
                                rows={2}
                                style={{
                                    width: "100%",
                                    outline: "none",
                                    borderRadius: "10px",
                                    marginBottom: "1.5rem",
                                    borderColor: "#DDCDC0",
                                    padding: "0.5rem 2rem",
                                    boxSizing: "border-box",
                                    height: "50px",
                                    fontWeight: "500" 
                                }}
                            >
                            </textarea>
                        </div>
                    );
                })}

             <div className="section2_btn_container" >
                 <button className="profile_button" style={{ margin: "auto" }} onClick={() => submitFunction('Pending')}>Save</button><br />
                <button className="profile_button" style={{ margin: "auto" }} onClick={showModal}>Submit</button>
            </div>                
                {/* {submissionStatus === 'success' && <p style={{ color: "green" }}>{message}</p>} */}
                {submissionStatus === 'error' && <p style={{ color: "red" }}>
                    "Phase 2 not enabled yet, please check with admin to enable phase 2"
                </p>}
            </div>



     <Modal
        open={open}
        // title="Alert"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={()=>submitFunction('Complete')} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >Yes</Button>
            {/* <Button onClick={handlelogout} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >No</Button> */}
            <Button onClick={handleCancel} style={{backgroundColor:"#000",color:"#fff",border:"none"}} >Cancel</Button>
          </>
        )}
      >
        <br />
        <h4>Are you sure you want to submit this form now ? 
           <span style={{color:"#C6222F", display:"block", fontSize:"16px", marginTop:"1rem"}} >Pls note that once you submit, you will not be able to make any change</span> 
        </h4>
        <br />
      </Modal> 



    <Modal
        open={open2}
        // title="Alert"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={navigatetoprofile} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >Ok</Button>
          </>
        )}
      >
        <br />
        <h4>{message}</h4>
        <br />
      </Modal> 




        </>
    );
}

export default QuestionComp;




