import React, { useEffect, useRef, useState } from "react";
import { message } from 'antd';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { baseurl } from "../commonComponet/apibaseurl";

function PersonalInformation({ value, onClick, setFormData, submitfunctionNext, totalAgefunction }) {
    const { first_name, last_name, mobile_number, email, date_of_birth, gender, country_code } = value;
    const lastNameRef = useRef(null);
    const firstNameRef = useRef(null);
    const emailRef = useRef(null);
    const mobileNumberRef = useRef(null);
    const countrycodeRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();

    const [countryCodes, setCountryCodes] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState(country_code || "+44");
    const [mobileNumber, setMobileNumber] = useState(mobile_number);
    const [age, setAge] = useState(null);
    const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(date_of_birth ? new Date(date_of_birth) : null);

console.log("selectedDateOfBirth",date_of_birth);

    useEffect(() => {
        const apiEndpoint = `${baseurl}/api/country-dial-codes/`;

        fetch(apiEndpoint)
            .then(response => response.json())
            .then(data => {
                if (data.success === "1") {
                    setCountryCodes(data.country_codes);
                } else {
                    console.error("Failed to fetch country codes");
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);

    useEffect(() => {
        setMobileNumber(mobile_number);
    }, [mobile_number]);

    useEffect(() => {
        if (date_of_birth) {
            const parsedDate = new Date(date_of_birth);
            setSelectedDateOfBirth(parsedDate);
            setAge(calculateAge(parsedDate));
        }
    }, [date_of_birth]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if ((name === 'first_name' || name === 'last_name') && !/^[a-zA-Z]*$/.test(value)) {
            messageApi.open({ type: 'error', content: 'Only letters are allowed!' });
            return;
        }

        if (name === "mobile_number") {
            if (value.length <= 10) {
                setFormData(prevData => ({
                    ...prevData,
                    [name]: value,
                }));
                setMobileNumber(value);
            }
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleCountryCodeChange = (e) => {
        const countryCode = e.target.value;
        setSelectedCountryCode(countryCode);
        setFormData(prevData => ({
            ...prevData,
            country_code: countryCode,
        }));
    };

    const handleStepChange = (e) => {
        submitfunctionNext();
        e.preventDefault();
        const lastNameValue = lastNameRef.current.value.trim();
        const firstNameValue = firstNameRef.current.value.trim();
        const emailValue = emailRef.current.value.trim();
        const mobileNumberValue = mobileNumberRef.current.value.trim();

        if (lastNameValue === "") {
            messageApi.open({ type: 'error', content: 'Last name is required!' });
            return;
        }

        if (firstNameValue === "") {
            messageApi.open({ type: 'error', content: 'First name is required!' });
            return;
        }

        if (!selectedDateOfBirth) {
            messageApi.open({ type: 'error', content: 'Date of birth is required!' });
            return;
        }

        const dob = new Date(selectedDateOfBirth);
        const minDate = new Date('1950-01-01');
        const maxDate = new Date('2005-12-31');

        if (dob < minDate || dob > maxDate) {
            messageApi.open({ type: 'error', content: 'Date of birth must be between 1950-01-01 and 2005-12-31!' });
            return;
        }

        if (gender === "") {
            messageApi.open({ type: 'error', content: 'Gender is required!' });
            return;
        }

        if (emailValue === "") {
            messageApi.open({ type: 'error', content: 'Email is required!' });
            return;
        }

        if (mobileNumberValue === "" || mobileNumberValue.length < 10) {
            messageApi.open({ type: 'error', content: 'A valid mobile number is required!' });
            return;
        }

        // Format date to yyyy-MM-dd
        const formattedDateOfBirth = `${selectedDateOfBirth.getFullYear()}-${String(selectedDateOfBirth.getMonth() + 1).padStart(2, '0')}-${String(selectedDateOfBirth.getDate()).padStart(2, '0')}`;

        setFormData(prevData => ({
            ...prevData,
            last_name: lastNameValue.charAt(0).toUpperCase() + lastNameValue.slice(1),
            first_name: firstNameValue.charAt(0).toUpperCase() + firstNameValue.slice(1),
            date_of_birth: formattedDateOfBirth,
            email: emailValue,
        }));

        onClick("step2");
    };

    // Age calculation 
    function calculateAge(dob) {
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        totalAgefunction(age);
        return age;
    }

    function formatDateForInput(dateString) {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${year}-${month}-${day}`;
    }

    return (
        <form>
            <div className="inner_loginform_container">
                <div className="title">Personal Information</div>

                <div>
                    <label htmlFor="first_name">First name*</label>
                    <input
                        className="inpufeild custom_capitalize"
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={first_name}
                        ref={firstNameRef}
                        onChange={handleChange}
                        placeholder="Enter first name"
                        maxLength="20"
                    />
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    {contextHolder}
                    <label htmlFor="last_name">Last name*</label>
                    <input
                        className="inpufeild custom_capitalize"
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={last_name}
                        ref={lastNameRef}
                        onChange={handleChange}
                        placeholder="Enter last Name"
                        maxLength="20"
                    />
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="date_of_birth">Date of birth*</label>
                    <div className="inpufeild">
                        <DatePicker
                        style={{border:"none", outline:"none"}}
                            selected={selectedDateOfBirth}
                            onChange={(date) => {
                                setSelectedDateOfBirth(date);
                                const formattedDate = date ? formatDateForInput(date.toISOString()) : "";
                                setFormData(prevData => ({
                                    ...prevData,
                                    date_of_birth: formattedDate,
                                }));
                                
                                setAge(calculateAge(formattedDate));
                            }}
                            dateFormat="yyyy-MM-dd"
                            minDate={new Date('1950-01-01')}
                            maxDate={new Date('2005-12-31')}
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            placeholderText="Select date of birth"
                        />
                        {age !== null && (
                            <span style={{ marginLeft: "1rem" }}>Age: {age}</span>
                        )}
                    </div>
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="gender">Gender*</label>
                    <div className="radio_container">
                        <input
                            type="radio"
                            id="male"
                            name="gender"
                            value="Male"
                            checked={gender === "Male"}
                            onChange={handleChange}
                        />{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp; Male &nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="radio"
                            id="female"
                            name="gender"
                            value="Female"
                            checked={gender === "Female"}
                            onChange={handleChange}
                        />{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp; Female
                    </div>
                </div>


                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="email">Email*</label>
                    <input
                        style={{ textTransform: "lowercase" }}
                        className="inpufeild"
                        type="email"
                        disabled={true}
                        id="email"
                        name="email"
                        value={email}
                        ref={emailRef}
                        onChange={handleChange}
                        placeholder="Enter email address"
                    />
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="mobile_number">Mobile Number*</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <select
                            id="country-select"
                            style={{ outline: "none", border: "none", marginTop: "0.5rem", backgroundColor: "transparent" }}
                            onChange={handleCountryCodeChange}
                            value={selectedCountryCode}
                            ref={countrycodeRef}
                        >
                            {countryCodes.map((country, index) => (
                                <option key={index} value={country.dial_code}>
                                    {country.dial_code}
                                </option>
                            ))}
                        </select>

                        <input
                            type="number"
                            className="inpufeild custom-phone-input custom-phone-input_mobile_number number_input"
                            id="mobile_number"
                            name="mobile_number"
                            value={mobileNumber}
                            ref={mobileNumberRef}
                            onChange={handleChange}
                            placeholder="Enter mobile number"
                            maxLength="10"
                        />
                    </div>
                </div>

                <button onClick={handleStepChange} style={{ marginTop: "1.8rem" }} className="loginBtn" type="submit">
                    NEXT STEP
                </button>

                <br />
                <br />
            </div>
        </form>
    );
}

export default PersonalInformation;
