import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import { message, Modal } from 'antd';

const LivePhotoCapture = ({ setImageurl, initialPhoto, updatLivePhotoProps, testingProps, livePhotoProps2, testing1 }) => {
  const webcamRef = useRef(null);
  const [photoData, setPhotoData] = useState(livePhotoProps2);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isWebcamOn, setIsWebcamOn] = useState(false);
  const [isPermissionModalVisible, setIsPermissionModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  
  const [messageApi, contextHolder] = message.useMessage();
  const [backgroundPhoto, setBackgroundPhoto] = useState(livePhotoProps2);

  const requestCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      onWebCam();
      return true;
    } catch (error) {
      console.log('Permission not granted:', error);
      setModalContent(
        <>
          <p>Please grant camera permission to use this feature.</p>
          <p>Follow these steps to enable camera access:</p>
          <ul>
            <li>Check your browser settings to allow camera access.</li>
            <li>Ensure your camera is connected and not being used by another application.</li>
            <li>For more help, visit the following links:</li>
            <li><a href="https://support.google.com/chrome/answer/2693767">Chrome Camera Access</a></li>
            <li><a href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions">Firefox Camera Access</a></li>
            <li><a href="https://support.apple.com/guide/safari/use-camera-and-microphone-sfri40734/mac">Safari Camera Access</a></li>
          </ul>
        </>
      );
      setIsPermissionModalVisible(true);
      return false;
    }
  };

  const checkCameraPermission = async () => {
    try {
      const result = await navigator.permissions.query({ name: 'camera' });
      if (result.state === 'granted') {
        onWebCam();
        return true;
      } else if (result.state === 'prompt') {
        return requestCameraPermission();
      } else {
        console.log('Permission not granted');
        // messageApi.open({ type: 'error', content: 'Camera permission is not granted' });
        setModalContent(
          <>
            <p>Please grant camera permission to use this feature.</p>
            <p>Follow these steps to enable camera access:</p>
            <ul>
              <li>Check your browser settings to allow camera access.</li>
              <li>Ensure your camera is connected and not being used by another application.</li>
              <li>For more help, visit the following links:</li>
              <li><a href="https://support.google.com/chrome/answer/2693767">Chrome Camera Access</a></li>
              <li><a href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions">Firefox Camera Access</a></li>
              <li><a href="https://support.apple.com/guide/safari/use-camera-and-microphone-sfri40734/mac">Safari Camera Access</a></li>
            </ul>
          </>
        );
        setIsPermissionModalVisible(true);
        return false;
      }
    } catch (error) {
      console.log('Error checking camera permission:', error);
      messageApi.open({ type: 'error', content: 'Camera service is unavailable' });
      setModalContent(
        <>
          <p>Camera service is unavailable on this device.</p>
          <p>Please ensure your camera is properly connected and functioning.</p>
        </>
      );
      setIsPermissionModalVisible(true);
      return false;
    }
  };

  const onWebCam = () => {
    setIsWebcamOn(true);
  };

  const stopCapture = () => {
    setIsCapturing(false);
    setIsWebcamOn(false);
  };

  const startCapture = () => {
    setIsCapturing(true);
  };

  useEffect(() => {
    const capturePhoto = () => {
      if (isCapturing && webcamRef.current) {
        const photo = webcamRef.current.getScreenshot();
        setImageurl(photo);
        updatLivePhotoProps(photo);
        setBackgroundPhoto(photo);
        testing1(photo);
        stopCapture();
      }
    };

    capturePhoto();
  }, [isCapturing, setImageurl, updatLivePhotoProps]);

  const handleCameraButtonClick = async () => {
    const hasPermission = await checkCameraPermission();
    if (hasPermission) {
      const modalTriggerButton = document.getElementById('modalTriggerButton');
      if (modalTriggerButton) {
        modalTriggerButton.click();
      }
    }
  };

  return (
    <div>
      {contextHolder}
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button onClick={stopCapture} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body custom_body_modal">
              {isWebcamOn && <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={startCapture} style={{ backgroundColor: '#C6222F', border: 'none' }} data-bs-dismiss="modal" aria-label="Close">Capture</button>
            </div>
          </div>
        </div>
      </div>
      <br />
      <button className='camerastartCapture_btn' onClick={handleCameraButtonClick}>
        <div className='camerastartCapture'
          id="heard_from"
          name="heard_from"
          style={{ backgroundImage: `url(${backgroundPhoto === '' ? (photoData == null ? '/images/camerabg.png' : photoData) : backgroundPhoto})` }}>
        </div>
        <div className='camerastartCapture_overlay'>
          <img className='cameraIcon' src="/images/cameraIcon.png" alt="cameraIcon" />
        </div>
      </button>

      <button id="modalTriggerButton" type="button" style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#exampleModal"></button>

      <Modal
        title="Camera Permission Required"
        visible={isPermissionModalVisible}
        onCancel={() => setIsPermissionModalVisible(false)}
        footer={[
          <button className='live_photo_btn' key="ok" onClick={() => setIsPermissionModalVisible(false)}>
            OK
          </button>
        ]}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default LivePhotoCapture;


