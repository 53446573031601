import React, { useState, useEffect } from "react";
import { NavLink, redirect } from "react-router-dom";
import './index.css';
import Header from "./commonComponet/header";
import Copyright from "./commonComponet/copyright";
import { baseurl } from "./commonComponet/apibaseurl";
import DatePicker from 'react-datepicker';

function LoginScreen() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);



    // Clear input field on page refresh
    useEffect(() => {
        setUsername("");
    }, []);

    console.log(baseurl);

    const handleLogin = async () => {
        try {
            const response = await fetch(`${baseurl}/api/customer-login/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password }),
            });
            const data = await response.json();
            console.log(data.message);
            if (data.message === "Logged in successfully") {
                localStorage.setItem("token", data.token);
                if (data.phase_1_status !== "Completed") {
                    window.location.href = "/multistepform";
                } else {
                    window.location.href = "/profile";
                }
            } else {
                setError(data.message);
            }
        } catch (error) {
            setError("An error occurred. Please try again later.");
        }
    };

    useEffect(() => {
        localStorage.setItem("username", username); 
    }, [username]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="login_page_container">
                <div className="header_container">
                    <nav className="header_nav">
                        <div className="menu_container">
                            <div className="logo_container"></div>
                        </div>
                    </nav>
                </div>

                <section>
                    <div className="login_section login_section_mobile">
                        <div className="login_sidebar"></div>
                        <div className="login_form_container login_form_container_mobile">
                            <div className="inner_loginform_container inner_loginform_container_mobile">
                                <div className="title">Login</div>

                                <div>
                                    <label htmlFor="">Email</label>
                                    <input
                                        className="inpufeild"
                                        type="email"
                                        placeholder="Enter Email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>

                                <div style={{ marginTop: "1.8rem" }}>
                                    <label htmlFor="">Password</label>
                                    <div className="password-container" style={{display:"flex",alignItems:"center"}} >
                                        <input
                                            className="inpufeild"
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Enter password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <span
                                            style={{marginLeft:"-10%", backgroundColor:"#F9F6F2", width:"10%"}}
                                            className="toggle-password-btn"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {showPassword ?
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{float:"right"}} width="20px" height="20px" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0"/></svg>
                                             : 
                                             <svg xmlns="http://www.w3.org/2000/svg" style={{float:"right"}} width="20px" height="20px" viewBox="0 0 24 24"><path fill="currentColor" d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.8 11.8 0 0 1-4 5.19l-1.42-1.43A9.86 9.86 0 0 0 20.82 12A9.82 9.82 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.82 9.82 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13"/></svg>
                                             }
                                        </span>
                                    </div>
                                </div>

                                <button
                                    style={{ marginTop: "29px" }}
                                    className="loginBtn login_page_button"
                                    onClick={handleLogin}
                                >
                                    LOGIN
                                </button>
                                {error && <p className="form_text">{error}</p>}

                                <br /><br />
                                    <div className="dropdown_menu_container">
                                    <h6>Forgotten your Password ?</h6>
                                    <NavLink to="/update-password" style={{ textDecoration: "none", color: "#C6222F" }}>
                                    <h6>click to reset</h6>
                                    </NavLink>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="login_copy_right_container">
                    <Copyright />
                </div>
            </div>
            {/* <div className="inpufeild">
                        <DatePicker
                            selected={date_of_birth ? new Date(date_of_birth) : null}
                            onChange={handleDateChange}
                            dateFormat="yyyy-MM-dd"
                            maxDate={new Date('2005-12-31')}
                            minDate={new Date('1950-01-01')}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                            ref={dateOfBirthRef}
                            placeholderText="Select date of birth"
                        />
                        {age !== null && (
                            <span style={{ marginLeft: "1rem" }}>Age: {age}</span>
                        )}
                    </div> */}
        </>
    );
}

export default LoginScreen;

