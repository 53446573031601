import React, { useRef } from "react";
import { message } from 'antd';

function Step2({ value, onClick, setFormData,phase1statusValue, submitfunctionNext}) {
    const { address_line_1, address_line_2, city, country, postal_code } = value;

    const addressLine1Ref = useRef(null);
    const addressLine2Ref = useRef(null);
    const cityRef = useRef(null);
    const countryRef = useRef(null);
    const postalCodeRef = useRef(null);

    const [messageApi, contextHolder] = message.useMessage();


    const handleStepChange = (e) => {
        // phase1statusValue("Incomplete");
        submitfunctionNext();
        e.preventDefault();
        const addressLine1Value = addressLine1Ref.current.value.trim();
        const addressLine2Value = addressLine2Ref.current.value.trim();
        const cityValue = cityRef.current.value.trim();
        const countryValue = countryRef.current.value;
        const postalCodeValue = postalCodeRef.current.value.trim();

        if (addressLine1Value === "") {
            messageApi.open({ type: 'error', content: 'Address line 1 is required!' });
            return;
        } else if (addressLine1Value.length > 50) {
            messageApi.open({ type: 'error', content: 'Address line 1 must be 50 characters or less!' });
            return;
        }

        if (addressLine2Value.length > 50) {
            messageApi.open({ type: 'error', content: 'Address line 2 must be 50 characters or less!' });
            return;
        }

        if (cityValue === "") {
            messageApi.open({ type: 'error', content: 'City is required!' });
            return;
        } else if (cityValue.length > 50) {
            messageApi.open({ type: 'error', content: 'City must be 20 characters or less!' });
            return;
        }

        if (countryValue === "") {
            messageApi.open({ type: 'error', content: 'Country is required!' });
            return;
        }

        if (postalCodeValue === "") {
            messageApi.open({ type: 'error', content: 'Postal code is required!' });
            return;
        } else if (postalCodeValue.length > 10) {
            messageApi.open({ type: 'error', content: 'Postal code must be 10 characters or less!' });
            return;
        }

        setFormData((prevData) => ({
            ...prevData,
            address_line_1: addressLine1Value.charAt(0).toUpperCase() + addressLine1Value.slice(1),
            address_line_2: addressLine2Value.charAt(0).toUpperCase() + addressLine2Value.slice(1),
            city: cityValue.charAt(0).toUpperCase() + cityValue.slice(1),
            country: countryValue.charAt(0).toUpperCase() + countryValue.slice(1),
            postal_code: postalCodeValue,
        }));
        onClick("family");
    };

    const handleStepChangeback = (e) => {
        e.preventDefault();
        onClick("personal");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <>
            <div className="inner_loginform_container">
                <div className="title">Residential Information</div>
                <div>
                    {contextHolder}
                    <label htmlFor="address_line_1">Address line 1*</label>
                    <input
                        className="inpufeild custom_capitalize"
                        type="text"
                        id="address_line_1"
                        name="address_line_1"
                        value={address_line_1}
                        ref={addressLine1Ref}
                        onChange={handleChange}
                        placeholder="Apartment/House number."
                        maxLength="50"
                    />
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="address_line_2">Address line 2</label>
                    <input
                        className="inpufeild custom_capitalize"
                        type="text"
                        id="address_line_2"
                        name="address_line_2"
                        value={address_line_2}
                        ref={addressLine2Ref}
                        onChange={handleChange}
                        placeholder="Street name."
                        maxLength="50"
                    />
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="city">City*</label>
                    <input
                        className="inpufeild custom_capitalize"
                        type="text"
                        id="city"
                        name="city"
                        value={city}
                        ref={cityRef}
                        onChange={handleChange}
                        placeholder="Enter City"
                        maxLength="50"
                    />
                </div>
                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="country">Country*</label>
                      <select name="country" id="country" className="inpufeild" value={country} ref={countryRef} onChange={handleChange} >
                        <option value=""selected  >Select Country</option>
                        <option value="Afghanistan">Afghanistan</option>
  <option value="Albania">Albania</option>
  <option value="Algeria">Algeria</option>
  <option value="Andorra">Andorra</option>
  <option value="Angola">Angola</option>
  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
  <option value="Argentina">Argentina</option>
  <option value="Armenia">Armenia</option>
  <option value="Australia">Australia</option>
  <option value="Austria">Austria</option>
  <option value="Azerbaijan">Azerbaijan</option>
  <option value="Bahamas">Bahamas</option>
  <option value="Bahrain">Bahrain</option>
  <option value="Bangladesh">Bangladesh</option>
  <option value="Barbados">Barbados</option>
  <option value="Belarus">Belarus</option>
  <option value="Belgium">Belgium</option>
  <option value="Belize">Belize</option>
  <option value="Benin">Benin</option>
  <option value="Bhutan">Bhutan</option>
  <option value="Bolivia">Bolivia</option>
  <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
  <option value="Botswana">Botswana</option>
  <option value="Brazil">Brazil</option>
  <option value="Brunei">Brunei</option>
  <option value="Bulgaria">Bulgaria</option>
  <option value="Burkina Faso">Burkina Faso</option>
  <option value="Burundi">Burundi</option>
  <option value="Cabo Verde">Cabo Verde</option>
  <option value="Cambodia">Cambodia</option>
  <option value="Cameroon">Cameroon</option>
  <option value="Canada">Canada</option>
  <option value="Central African Republic">Central African Republic</option>
  <option value="Chad">Chad</option>
  <option value="Chile">Chile</option>
  <option value="China">China</option>
  <option value="Colombia">Colombia</option>
  <option value="Comoros">Comoros</option>
  <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
  <option value="Congo, Republic of the">Congo, Republic of the</option>
  <option value="Costa Rica">Costa Rica</option>
  <option value="Côte d'Ivoire">Côte d'Ivoire</option>
  <option value="Croatia">Croatia</option>
  <option value="Cuba">Cuba</option>
  <option value="Cyprus">Cyprus</option>
  <option value="Czech Republic">Czech Republic</option>
  <option value="Denmark">Denmark</option>
  <option value="Djibouti">Djibouti</option>
  <option value="Dominica">Dominica</option>
  <option value="Dominican Republic">Dominican Republic</option>
  <option value="Ecuador">Ecuador</option>
  <option value="Egypt">Egypt</option>
  <option value="El Salvador">El Salvador</option>
  <option value="Equatorial Guinea">Equatorial Guinea</option>
  <option value="Eritrea">Eritrea</option>
  <option value="Estonia">Estonia</option>
  <option value="Eswatini">Eswatini</option>
  <option value="Ethiopia">Ethiopia</option>
  <option value="Fiji">Fiji</option>
  <option value="Finland">Finland</option>
  <option value="France">France</option>
  <option value="Gabon">Gabon</option>
  <option value="Gambia">Gambia</option>
  <option value="Georgia">Georgia</option>
  <option value="Germany">Germany</option>
  <option value="Ghana">Ghana</option>
  <option value="Greece">Greece</option>
  <option value="Grenada">Grenada</option>
  <option value="Guatemala">Guatemala</option>
  <option value="Guinea">Guinea</option>
  <option value="Guinea-Bissau">Guinea-Bissau</option>
  <option value="Guyana">Guyana</option>
  <option value="Haiti">Haiti</option>
  <option value="Honduras">Honduras</option>
  <option value="Hungary">Hungary</option>
  <option value="Iceland">Iceland</option>
  <option value="India">India</option>
  <option value="Indonesia">Indonesia</option>
  <option value="Iran">Iran</option>
  <option value="Iraq">Iraq</option>
  <option value="Ireland">Ireland</option>
  <option value="Israel">Israel</option>
  <option value="Italy">Italy</option>
  <option value="Jamaica">Jamaica</option>
  <option value="Japan">Japan</option>
  <option value="Jordan">Jordan</option>
  <option value="Kazakhstan">Kazakhstan</option>
  <option value="Kenya">Kenya</option>
  <option value="Kiribati">Kiribati</option>
  <option value="Korea, North">Korea, North</option>
  <option value="Korea, South">Korea, South</option>
  <option value="Kuwait">Kuwait</option>
  <option value="Kyrgyzstan">Kyrgyzstan</option>
  <option value="Laos">Laos</option>
  <option value="Latvia">Latvia</option>
  <option value="Lebanon">Lebanon</option>
  <option value="Lesotho">Lesotho</option>
  <option value="Liberia">Liberia</option>
  <option value="Libya">Libya</option>
  <option value="Liechtenstein">Liechtenstein</option>
  <option value="Lithuania">Lithuania</option>
  <option value="Luxembourg">Luxembourg</option>
  <option value="Madagascar">Madagascar</option>
  <option value="Malawi">Malawi</option>
  <option value="Malaysia">Malaysia</option>
  <option value="Maldives">Maldives</option>
  <option value="Mali">Mali</option>
  <option value="Malta">Malta</option>
  <option value="Marshall Islands">Marshall Islands</option>
  <option value="Mauritania">Mauritania</option>
  <option value="Mauritius">Mauritius</option>
  <option value="Mexico">Mexico</option>
  <option value="Micronesia">Micronesia</option>
  <option value="Moldova">Moldova</option>
  <option value="Monaco">Monaco</option>
  <option value="Mongolia">Mongolia</option>
  <option value="Montenegro">Montenegro</option>
  <option value="Morocco">Morocco</option>
  <option value="Mozambique">Mozambique</option>
  <option value="Myanmar">Myanmar</option>
  <option value="Namibia">Namibia</option>
  <option value="Nauru">Nauru</option>
  <option value="Nepal">Nepal</option>
  <option value="Netherlands">Netherlands</option>
  <option value="New Zealand">New Zealand</option>
  <option value="Nicaragua">Nicaragua</option>
  <option value="Niger">Niger</option>
  <option value="Nigeria">Nigeria</option>
  <option value="Norway">Norway</option>
  <option value="Oman">Oman</option>
  <option value="Pakistan">Pakistan</option>
  <option value="Palau">Palau</option>
  <option value="Panama">Panama</option>
  <option value="Papua New Guinea">Papua New Guinea</option>
  <option value="Paraguay">Paraguay</option>
  <option value="Peru">Peru</option>
  <option value="Philippines">Philippines</option>
  <option value="Poland">Poland</option>
  <option value="Portugal">Portugal</option>
  <option value="Qatar">Qatar</option>
  <option value="Romania">Romania</option>
  <option value="Russia">Russia</option>
  <option value="Rwanda">Rwanda</option>
  <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
  <option value="Saint Lucia">Saint Lucia</option>
  <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
  <option value="Samoa">Samoa</option>
  <option value="San Marino">San Marino</option>
  <option value="Sao Tome and Principe">Sao Tome and Principe</option>
  <option value="Saudi Arabia">Saudi Arabia</option>
  <option value="Senegal">Senegal</option>
  <option value="Serbia">Serbia</option>
  <option value="Seychelles">Seychelles</option>
  <option value="Sierra Leone">Sierra Leone</option>
  <option value="Singapore">Singapore</option>
  <option value="Slovakia">Slovakia</option>
  <option value="Slovenia">Slovenia</option>
  <option value="Solomon Islands">Solomon Islands</option>
  <option value="Somalia">Somalia</option>
  <option value="South Africa">South Africa</option>
  <option value="South Sudan">South Sudan</option>
  <option value="Spain">Spain</option>
  <option value="Sri Lanka">Sri Lanka</option>
  <option value="Sudan">Sudan</option>
  <option value="Suriname">Suriname</option>
  <option value="Sweden">Sweden</option>
  <option value="Switzerland">Switzerland</option>
  <option value="Syria">Syria</option>
  <option value="Taiwan">Taiwan</option>
  <option value="Tajikistan">Tajikistan</option>
  <option value="Tanzania">Tanzania</option>
  <option value="Thailand">Thailand</option>
  <option value="Timor-Leste">Timor-Leste</option>
  <option value="Togo">Togo</option>
  <option value="Tonga">Tonga</option>
  <option value="Trinidad and Tobago">Trinidad and Tobago</option>
  <option value="Tunisia">Tunisia</option>
  <option value="Turkey">Turkey</option>
  <option value="Turkmenistan">Turkmenistan</option>
  <option value="Tuvalu">Tuvalu</option>
  <option value="Uganda">Uganda</option>
  <option value="Ukraine">Ukraine</option>
  <option value="United Arab Emirates">United Arab Emirates</option>
  <option value="United Kingdom">United Kingdom</option>
  <option value="United States">United States</option>
  <option value="Uruguay">Uruguay</option>
  <option value="Uzbekistan">Uzbekistan</option>
  <option value="Vanuatu">Vanuatu</option>
  <option value="Vatican City">Vatican City</option>
  <option value="Venezuela">Venezuela</option>
  <option value="Vietnam">Vietnam</option>
  <option value="Yemen">Yemen</option>
  <option value="Zambia">Zambia</option>
  <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                <label htmlFor="password">Postal code*</label>
                <input
                        className="inpufeild"
                        style={{textTransform:"uppercase"}}
                        type="text"
                        id="postal_code"
                        name="postal_code"
                        value={postal_code}
                        ref={postalCodeRef}
                        onChange={handleChange} 
                        placeholder="Enter postal code"
                        maxLength="10"
                    />
                 </div>

                <div className="btn_container">
                <button onClick={handleStepChangeback}  className="loginBtnBack" type="submit">
                   Back
                </button>

                <button onClick={handleStepChange}  className="loginBtn" type="submit">
                    Next Step
                </button>
    </div>

                <br /><br />
            </div>
        </>
    )
}
export default Step2