import React from "react";
import { useRoutes } from "react-router-dom";
import LoginScreen from "./loginScreen";
import MultiStepform from "./multiStepForm/multiStepform";
import Step2 from "./formsteps/step2";
import SubmitPage from "./pages/submit";
import ProfilePage from "./pages/ProfilePage/profilePage";
import ChangePassword from "./pages/password/chnagePassword";
import UpdatePassword from "./pages/password/updatePassword";
import ResetPassword from "./pages/password/resetPassword";


const ProjectRoutes = () => {
  let element = useRoutes([
    {path: "/", element: <LoginScreen/> },
    {path: "/multistepform", element: <MultiStepform/>},
    {path: "/submit", element: <SubmitPage/>},
    {path: "/step2", element: <Step2/>},
    {path: "/profile", element: <ProfilePage/>},
    {path: "/change-password", element: <ChangePassword/>},
    {path: "/update-password", element: <UpdatePassword/>},
    {path: "/reset-password", element: <ResetPassword/>}
  ]);

  return element;
};

export default ProjectRoutes;
