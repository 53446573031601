import React, { useEffect, useState } from "react";
import { baseurl } from "../../../commonComponet/apibaseurl";

function QuestionRead({instructions}) {
    const [isData, setIsData] = useState([]);
    const [answer, setAnswer] = useState([]);

    // console.log("instructions", instructions);
    
    function fetchData() {
        const token = localStorage.getItem("token");
        console.log(token);
        
        if (!token) {
            console.error("Token not found in localStorage");
            return;
        }
        
        fetch(`${baseurl}/api/list-phase-2-questions/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if(data.success==='4'){
              window.location.href = "/"
            }
            if(data.success==='1'){
              setIsData(data.data);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    console.log(isData);

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setAnswer((prevData) => {
            const existingAnswerIndex = prevData.findIndex(item => item.id === id);
            if (existingAnswerIndex >= 0) {
                // Update existing answer
                const updatedData = [...prevData];
                updatedData[existingAnswerIndex].answer = value;
                return updatedData;
            } else {
                // Add new answer
                return [...prevData, { id, answer: value }];
            }
        });
    };

    function submitFunction() {
        const token = localStorage.getItem("token");
        
        if (!token) {
            console.error("Token not found in localStorage");
            return;
        }
        
        const jsonData = JSON.stringify(answer);
        fetch(`${baseurl}/api/submit-phase-2-answers/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: jsonData
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            if(data.success==='4'){
              window.location.href = "/"
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    console.log(answer);

    return (
        <>
            <div className="shadow read_question_container" style={{margin: "auto", backgroundColor: "#fff" }}>
                {isData === null ? console.log("loading") : isData.map((items) => {
                    return (
                        <div key={items.id}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <h6 style={{ fontWeight: "500", color:"#a5a4a4" }}>{items.id}.&nbsp;{items.question}</h6>
                            </div>
                            <h6 style={{ fontWeight: "500" }}> &nbsp;&nbsp;&nbsp;&nbsp;{items.answer}</h6>
                            <hr style={{width:"100%", height:"2px", color:"DEDEDE", backgroundColor:"#DEDEDE"}} />
                        </div>
                    );
                })}

     <h5 style={{textAlign:"center"}} >{instructions}</h5>
            </div>
        </>
    );
}

export default QuestionRead;