// import React, { useRef } from "react";
// import {message, } from 'antd';

// function Family({value,onClick,setFormData,phase1statusValue, submitfunctionNext}){
//     const {father_name,mother_name,sibling_count} = value;
//     const fatherNameRef = useRef(null);
//     const motherNameRef = useRef(null);
//     const siblingCountRef = useRef(null);

//     const [messageApi, contextHolder] = message.useMessage();

//     const handleStepChange = (e) => {
//         // phase1statusValue("Incomplete");
//         submitfunctionNext("Incomplete");
//         e.preventDefault();
//         const fatherNameValue = fatherNameRef.current.value.trim();
//         const motherNameValue = motherNameRef.current.value.trim();
//         const siblingCounValue = siblingCountRef.current.value.trim();
    
//         if (fatherNameValue === "") {
//             messageApi.open({type: 'error',content: 'Father’s name is required!'});
//             return;
//         }
    
//         if (motherNameValue === "") {
//             messageApi.open({type: 'error',content: 'Mother’s name is required!'});
//             return;
//         }
    
//         if (siblingCounValue === "") {
//             messageApi.open({type: 'error',content: 'No. of siblings count is required!'});
//             return;
//         }    
    
//         setFormData((prevData) => ({
//             ...prevData,
//             father_name: fatherNameValue,
//             mother_name: motherNameValue,
//             sibling_count: siblingCounValue, 
//         }));
        
//         onClick("professional");
//     };

//     const handleStepChangeback = (e) => {
//         e.preventDefault();
//         onClick("step2");
//       };
//       const handleChange = (e) => {
//         const { name, value } = e.target;

//         // Validation for first name and last name
//         if ((name === 'father_name' || name === 'mother_name') && !/^[a-zA-Z" "]*$/.test(value)) {
//             messageApi.open({ type: 'error', content: 'Only letters are allowed!' });
//             return;
//         }

//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value,
//         }));
//     };
//     return(
//         <>

//           <div className=" inner_loginform_container_family">
//                 <div className="title">Family Information</div>
//                 {contextHolder}
//                 <div>
//                     <label htmlFor="fathername">Father’s name*</label>
//                     <input
//                         className="inpufeild custom_capitalize"
//                         type="text"
//                         id="father_name"
//                         name="father_name"
//                         value={father_name}
//                         ref={fatherNameRef}
//                         onChange={handleChange} 
//                         placeholder="Enter Father's Name"
//                         maxLength="20"
//                     />
//                 </div>

//                 <div style={{ marginTop: "1.8rem" }}>
//                     <label htmlFor="mathername">Mother’s name*</label>
//                     <input
//                         className="inpufeild custom_capitalize"
//                         type="text"
//                         id="mother_name"
//                         name="mother_name"
//                         value={mother_name}
//                         ref={motherNameRef}
//                         onChange={handleChange} 
//                         placeholder="Enter mother's name"
//                         maxLength="20"
//                     />
//                 </div>

//                 <div style={{ marginTop: "1.8rem" }}>
//      <label htmlFor="password">No. of siblings*</label>

//      <select className="select_height"
//                              name="sibling_count" 
//                              id="sibling_count"
//                              value={sibling_count}
//                              ref={siblingCountRef}
//                              onChange={handleChange}
//                              >
//                             <option selected value="">Select</option>
//                                 <option value="0">0</option>
//                                 <option value="1">1</option>
//                                 <option value="2">2</option>
//                                 <option value="3">3</option>
//                                 <option  value="4">4</option>
//                                 <option  value="5">5</option>
//                                 <option value="6">6</option>
//                                 <option value="7">7</option>
//                                 <option value="8">8</option>
//                                 <option value="9">9</option>
//                                 <option value="10">10</option>
//                             </select>

//                 </div>

//   <div className="btn_container">
// <button onClick={handleStepChangeback} className="loginBtnBack" type="submit">
//                    Back
//                 </button>

//                 <button onClick={handleStepChange} className="loginBtn" type="submit">
//                     Next Step
//                 </button>
// </div>


// <div style={{height:"12rem", width:"100%"}}></div>
//             </div>
    
//         </>
//     );
// }
// export default Family;


import React, { useRef } from "react";
import { message } from 'antd';

function Family({ value, onClick, setFormData, phase1statusValue, submitfunctionNext }) {
  const { father_name, mother_name, sibling_count } = value;
  const fatherNameRef = useRef(null);
  const motherNameRef = useRef(null);
  const siblingCountRef = useRef(null);

  const [messageApi, contextHolder] = message.useMessage();

  const handleStepChange = async (e) => {
    e.preventDefault();
    const fatherNameValue = fatherNameRef.current.value.trim();
    const motherNameValue = motherNameRef.current.value.trim();
    const siblingCounValue = siblingCountRef.current.value.trim();

    if (fatherNameValue === "") {
      messageApi.open({ type: 'error', content: 'Father’s name is required!' });
      return;
    }

    if (motherNameValue === "") {
      messageApi.open({ type: 'error', content: 'Mother’s name is required!' });
      return;
    }

    if (siblingCounValue === "") {
      messageApi.open({ type: 'error', content: 'No. of siblings count is required!' });
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      father_name: fatherNameValue.charAt(0).toUpperCase() + fatherNameValue.slice(1),
      mother_name: motherNameValue.charAt(0).toUpperCase() + motherNameValue.slice(1),
      sibling_count: siblingCounValue,
    }));

    // Update status before moving to next step
    await submitfunctionNext();
    onClick("professional");
  };

  const handleStepChangeback = (e) => {
    e.preventDefault();
    onClick("step2");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if ((name === 'father_name' || name === 'mother_name') && !/^[a-zA-Z" "]*$/.test(value)) {
      messageApi.open({ type: 'error', content: 'Only letters are allowed!' });
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  
  return (
    <>
      <div className="inner_loginform_container_family">
        <div className="title">Family Information</div>
        {contextHolder}
        <div>
          <label htmlFor="fathername">Father’s name*</label>
          <input
            className="inpufeild custom_capitalize"
            type="text"
            id="father_name"
            name="father_name"
            value={father_name}
            ref={fatherNameRef}
            onChange={handleChange}
            placeholder="Enter Father's Name"
            maxLength="20"
          />
        </div>

        <div style={{ marginTop: "1.8rem" }}>
          <label htmlFor="mathername">Mother’s name*</label>
          <input
            className="inpufeild custom_capitalize"
            type="text"
            id="mother_name"
            name="mother_name"
            value={mother_name}
            ref={motherNameRef}
            onChange={handleChange}
            placeholder="Enter mother's name"
            maxLength="20"
          />
        </div>

        <div style={{ marginTop: "1.8rem" }}>
          <label htmlFor="password">No. of siblings*</label>
          <select
            className="select_height"
            name="sibling_count"
            id="sibling_count"
            value={sibling_count}
            ref={siblingCountRef}
            onChange={handleChange}
          >
            <option selected value="">Select</option>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>

        <div className="btn_container">
          <button onClick={handleStepChangeback} className="loginBtnBack" type="submit">
            Back
          </button>

          <button onClick={handleStepChange} className="loginBtn" type="submit">
            Next Step
          </button>
        </div>

        <div style={{ height: "12rem", width: "100%" }}></div>
      </div>
    </>
  );
}
export default Family;
