import React, { useRef } from "react";
import {message, } from 'antd';

function Professional({value,onClick,setFormData,phase1statusValue,submitfunctionNext}){
    const {education,current_profession,company,designation,other_professional_details,education2,} = value;

    const educationRef = useRef(null);
    const education2Ref = useRef(null);
    const currentProfessionRef = useRef(null);
    const companyRef = useRef(null);
    const designationRef = useRef(null);
    const otherProfessionalDetailsRef = useRef(null);

    const [messageApi, contextHolder] = message.useMessage();

    const handleStepChange = (e) => {
        // phase1statusValue("Incomplete");
        submitfunctionNext();
        e.preventDefault();
        const educationValue = educationRef.current.value.trim();
        const education2Value = education2Ref.current.value.trim();
        const currentProfessionValue = currentProfessionRef.current.value.trim();
        const companyValue = companyRef.current.value.trim();
        const designationValue = designationRef.current.value.trim();
        const otherProfessionalDetailsValue = otherProfessionalDetailsRef.current.value.trim();
        if (educationValue === "") {
            messageApi.open({type: 'error', content: 'Education qualification is required!'});
            return;
        }
        // if (education2Value === "") {
        //     messageApi.open({type: 'error', content: 'Education qualification is required!'});
        //     return;
        // }
        // if (currentProfessionValue === "") {
        //     messageApi.open({type: 'error', content: 'Current profession is required!'});
        //     return;
        // }
        
        // if (companyValue === "") {
        //     messageApi.open({type: 'error', content: 'Company name is required!'});
        //     return;
        // }
        
        // if (designationValue === "") {
        //     messageApi.open({type: 'error', content: 'Designation is required!'});
        //     return;
        // }
        
        
        setFormData((prevData) => ({
            ...prevData,
            education: educationValue.charAt(0).toUpperCase() + educationValue.slice(1),
            education2: education2Value.charAt(0).toUpperCase() + education2Value.slice(1),
            current_profession: currentProfessionValue.charAt(0).toUpperCase() + currentProfessionValue.slice(1),
            company: companyValue.charAt(0).toUpperCase() + companyValue.slice(1),
            designation: designationValue.charAt(0).toUpperCase() + designationValue.slice(1),
            other_professional_details: otherProfessionalDetailsValue.charAt(0).toUpperCase() + otherProfessionalDetailsValue.slice(1),
        }));
        onClick("moreaboutyou");
    };

    const handleStepChangeback = (e) => {
        e.preventDefault();
        onClick("family");
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };  

    return(
        <>
              <div className="inner_loginform_container">
                <div className="title">Professional Information</div>
                {contextHolder}
                <div style={{}}>
                    <label htmlFor="education">Education (Last two qualifications)*</label>
                    <div>
                    <input
                        className="inpufeild custom_capitalize"
                        type="text"
                        id="education"
                        name="education"
                        value={education}
                        ref={educationRef}
                        onChange={handleChange}
                        placeholder="Enter qualification"
                        maxLength="40"
                    />
                    <br />
                        <input
                        className="inpufeild custom_capitalize"
                        type="text"
                        id="education2"
                        name="education2"
                        value={education2}
                        ref={education2Ref}
                        onChange={handleChange}
                        placeholder="Enter qualification"
                        maxLength="40"
                    />
                    </div>
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="current_profession">Current profession</label>
                    <input
                        className="inpufeild custom_capitalize"
                        type="text"
                        id="current_profession"
                        name="current_profession"
                        value={current_profession}
                        ref={currentProfessionRef}
                        onChange={handleChange}
                        placeholder="Enter profession"
                        maxLength="40"
                    />
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="company">Name of company</label>
                    <input
                        className="inpufeild custom_capitalize"
                        type="text"
                        id="company"
                        name="company"
                        value={company}
                        ref={companyRef}
                        onChange={handleChange}
                        placeholder="Enter name of company"
                        maxLength="40"
                    />
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="designation">Designation</label>
                    <input
                        className="inpufeild custom_capitalize"
                        type="mail"
                        id="designation"
                        name="designation"
                        value={designation}
                        ref={designationRef}
                        onChange={handleChange}
                        placeholder="Enter your designation in the company"
                        maxLength="40"
                    />
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="password">Any other professional details ( Maximum 350 characters )</label>
                    <textarea  className="textarea_class"
                    maxLength="350"
                        id="other_professional_details"
                        name="other_professional_details"                    
                        value={other_professional_details}
                        ref={otherProfessionalDetailsRef}
                        onChange={handleChange}                    
                     style={{width:"70%", height:"100px", marginTop:"1rem", padding:"1rem", resize:"none"}} placeholder="Please add any other professional and educational information which you feel is important to share with us.">

                    </textarea>
                </div>

                <div className="btn_container">
                <button onClick={handleStepChangeback} className="loginBtnBack" type="submit">
                   Back
                </button>

                <button onClick={handleStepChange}  className="loginBtn" type="submit">
                    Next Step
                </button>
</div>
                <br /><br />
            </div>
        </>
    );
}
export default Professional;