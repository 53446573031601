import React from "react";
import Header from "../commonComponet/header";
import Copyright from "../commonComponet/copyright";

function SubmitPage(){
    return(
        <>
         <Header/>
         <div className="submitted_content_container">
            <p className="submitted_Title">Submitted!</p>
            <p className="submitted_description">We have received your information. We will get back to you shortly via your preferred communication method.</p>
            <div className="submit_animation">
               <img src="images/animation_submit.gif" alt="animation" />
            </div>
         </div>
         <Copyright/>
        </>
    )
}
export default SubmitPage;