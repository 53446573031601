// import React, { useState } from "react";
// import Copyright from "../../commonComponet/copyright";
// import Header from "../../commonComponet/header";
// import { baseurl } from "../../commonComponet/apibaseurl";

// function ResetPassword() {
//     const [new_password, setPassword] = useState("");
//     const [error, setError] = useState("");
//     const [showPassword2, setShowPassword2] = useState(false);

//     const getTokenFromUrl = () => {
//         const urlParams = new URLSearchParams(window.location.search);
//         return urlParams.get("reset_token");  
//     };

//     const handleResetPassword = async () => {
//         const reset_token = getTokenFromUrl();
//         console.log(reset_token);
//         if (!reset_token) {
//             setError("Reset token is missing.");
//             return;
//         }

//         try {
//             const response = await fetch(`${baseurl}/api/reset-password2/`, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify({ new_password, reset_token }),
//             });
//             const data = await response.json();
//             console.log(data.message);
//             if (data.success === "1") {
//                 window.location.href = "/";
//             } else {
//                 if (data.message === "Invalid token") {
//                     window.location.href = "/";
//                 } else {
//                     setError(data.message);
//                 }
//             }
//         } catch (error) {
//             setError("An error occurred. Please try again later.");
//         }
//     };

//     const togglePasswordVisibility2 = () => {
//         setShowPassword2(!showPassword2);
//     };

//     return (
//         <>
//             <div className="login_page_container">
//                 <Header />
//                 <section>
//                     <div className="login_section login_section_mobile">
//                         <div className="login_sidebar"></div>
//                         <div className="login_form_container login_form_container_mobile">
//                             <div style={{ height: "2rem" }}></div>
//                             <div className="inner_loginform_container inner_loginform_container_mobile">
//                                 <div className="title">Reset Password</div>

//                                 <div style={{ marginTop: "1.8rem" }}>
//                                     <label htmlFor="">Password</label>
//                                     <div className="password-container" style={{ display: "flex", alignItems: "center" }}>
//                                         <input
//                                             className="inpufeild"
//                                             type={showPassword2 ? "text" : "password"}
//                                             placeholder="Enter New Password"
//                                             onChange={(e) => setPassword(e.target.value)}
//                                             maxLength="10"
//                                         />
//                                         <span
//                                             style={{ marginLeft: "-2rem" }}
//                                             className="toggle-password-btn"
//                                             onClick={togglePasswordVisibility2}
//                                         >
//                                             {showPassword2 ? (
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
//                                                     <path fill="currentColor" d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0" />
//                                                 </svg>
//                                             ) : (
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
//                                                     <path fill="currentColor" d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.8 11.8 0 0 1-4 5.19l-1.42-1.43A9.86 9.86 0 0 0 20.82 12A9.82 9.82 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.82 9.82 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13" />
//                                                 </svg>
//                                             )}
//                                         </span>
//                                     </div>
//                                 </div>
                                

//                                 <button
//                                     style={{ marginTop: "29px" }}
//                                     className="loginBtn"
//                                     onClick={handleResetPassword}
//                                 >
//                                     CHANGE PASSWORD
//                                 </button>
//                                 {error && <p className="form_text">{error}</p>}
//                             </div>
//                             <div style={{ height: "2rem" }}></div>
//                         </div>
//                     </div>
//                 </section>
//                 <div className="login_copy_right_container">
//                     <Copyright />
//                 </div>
//             </div>
//         </>
//     );
// }

// export default ResetPassword;



import React, { useState } from "react";
import Copyright from "../../commonComponet/copyright";
import Header from "../../commonComponet/header";
import { baseurl } from "../../commonComponet/apibaseurl";
import { Button, Modal, Space } from 'antd';
import { NavLink } from "react-router-dom";

function ResetPassword() {
    const [new_password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword2, setShowPassword2] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [successmessege, setSuccessmessege] = useState("");


    const [open2, setOpen2] = useState(false);

    const showModal2 = () => {
        setOpen2(true);
      };

function nevigatetoLogin(){
    window.location.href = "/";
}


    const getTokenFromUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("reset_token");  // Updated parameter name here
    };

    const handleResetPassword = async () => {
        if (new_password !== confirm_password) {
            setError("Passwords do not match.");
            return;
        }

        const reset_token = getTokenFromUrl();
        console.log(reset_token);
        if (!reset_token) {
            setError("Reset token is missing.");
            return;
        }

        try {
            const response = await fetch(`${baseurl}/api/reset-password2/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ new_password, reset_token }),
            });
            const data = await response.json();
            console.log(data.message);
            if (data.success === "1") {
                showModal2()
                setSuccessmessege(data.message)
                // window.location.href = "/";
            } else {
                if (data.message === "Invalid token") {
                    window.location.href = "/";
                } else {
                    setError(data.message);
                }
            }
        } catch (error) {
            setError("An error occurred. Please try again later.");
        }
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <>
            <div className="login_page_container">
            <div className="header_container">
                    <nav className="header_nav">
                        <div className="menu_container">
                            <div className="logo_container"></div>
                        </div>
                    </nav>
                </div>


                <section>
                    <div className="login_section login_section_mobile">
                        <div className="login_sidebar"></div>
                        <div className="login_form_container login_form_container_mobile">
                            <div style={{ height: "2rem" }}></div>
                            <div className="inner_loginform_container inner_loginform_container_mobile">
                                <div className="title" style={{lineHeight:"1rem"}}>Reset Password</div>
                                <p className="" style={{lineHeight:"2rem", marginBottom:""}}>Please create new password</p>

                                <div style={{ marginTop: "1.8rem" }}>
                                    <label htmlFor="new_password"  >Password</label>
                                    <div className="password-container" style={{ display: "flex", alignItems: "center" }}>
                                        <input
                                            className="inpufeild"
                                            type={showPassword2 ? "text" : "password"}
                                            placeholder="Enter New Password"
                                            value={new_password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            maxLength="50"
                                            id="new_password"
                                        />
                                        <span
                                           style={{marginLeft:"-10%", backgroundColor:"#F9F6F2", width:"10%"}}
                                            className="toggle-password-btn"
                                            onClick={togglePasswordVisibility2}
                                        >
                                            {showPassword2 ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{float:"right"}} width="20px" height="20px" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{float:"right"}} width="20px" height="20px" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.8 11.8 0 0 1-4 5.19l-1.42-1.43A9.86 9.86 0 0 0 20.82 12A9.82 9.82 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.82 9.82 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13" />
                                                </svg>
                                            )}
                                        </span>
                                    </div>
                                </div>

                                <div style={{ marginTop: "1.8rem" }}>
                                    <label htmlFor="confirm_password">Confirm Password</label>
                                    <div className="password-container" style={{ display: "flex", alignItems: "center" }}>
                                        <input
                                            className="inpufeild"
                                            type={showConfirmPassword ? "text" : "password"}
                                            placeholder="Confirm New Password"
                                            value={confirm_password}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            maxLength="50"
                                            id="confirm_password"
                                        />
                                        <span
                                            style={{marginLeft:"-10%", backgroundColor:"#F9F6F2", width:"10%"}}
                                            className="toggle-password-btn"
                                            onClick={toggleConfirmPasswordVisibility}
                                        >
                                            {showConfirmPassword ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{float:"right"}} width="20px" height="20px" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{float:"right"}} width="20px" height="20px" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.8 11.8 0 0 1-4 5.19l-1.42-1.43A9.86 9.86 0 0 0 20.82 12A9.82 9.82 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.82 9.82 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13" />
                                                </svg>
                                            )}
                                        </span>
                                    </div>
                                </div>

                                <button
                                    style={{ marginTop: "29px" }}
                                    className="loginBtn"
                                    onClick={handleResetPassword}
                                >
                                   RESET
                                </button>

                             <br /><br />
                                 <div className="dropdown_menu_container">
                                    <h6>Click here to </h6>
                                    <NavLink to="/" style={{ textDecoration: "none", color: "#C6222F" }}>
                                    <h6>Login</h6>
                                    </NavLink>
                                    </div>

                                {error && <p className="form_text">{error}</p>}
                            </div>
                            <div style={{ height: "2rem" }}></div>
                        </div>
                    </div>
                </section>
                <div className="login_copy_right_container">
                    <Copyright />
                </div>
            </div>



            <Modal
        open={open2}
        // title="Alert"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={nevigatetoLogin} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >Ok</Button>
          </>
        )}
      >
        <br />
        <h4>{successmessege}</h4>
        <br />
      </Modal> 

      

        </>
    );
}

export default ResetPassword;
