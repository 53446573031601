import React from "react";

 function Copyright(){
    return(
        <>

        <div className="copy_right_container">
            <div>Copyright 2024 - MatchBox</div>
        </div>
        
        </>
    );
}
export default Copyright;