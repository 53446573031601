// import React, { useState, useEffect } from "react";
// import Copyright from "../../commonComponet/copyright";
// import Header from "../../commonComponet/header";
// import { baseurl } from "../../commonComponet/apibaseurl";

// function ChangePassword() {
//     const [username, setUsername] = useState("");
//     const [error, setError] = useState("");

//     // Clear input field on page refresh
//     useEffect(() => {
//         setUsername("");
//     }, []);

//     const ResetPassword = async () => {
//         try {
//             const response = await fetch(`${baseurl}/api/forgot-password/`, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify({ username }),
//             });
//             const data = await response.json();
//             console.log(data.message);
//             if (data.success === "1") {
//                 window.location.href = "/reset-password";
//                 console.log(data.message);
//             } else {
//                 if (data.success === "4" || data.success === "5") {
//                     window.location.href = "/";
//                 } else {
//                     setError(data.message);
//                 }
//             }
//         } catch (error) {
//             setError("An error occurred. Please try again later.");
//         }
//     };

//     return (
//         <>
//             <div className="login_page_container">
//                 <Header />
//                 <section>
//                     <div className="login_section login_section_mobile">
//                         <div className="login_sidebar"></div>
//                         <div className="login_form_container login_form_container_mobile">
//                             <div className="inner_loginform_container inner_loginform_container_mobile">
//                                 <div className="title">Update Password</div>

//                                 <div>
//                                     <label htmlFor="">Username</label>
//                                     <input
//                                         className="inpufeild"
//                                         type="text"
//                                         placeholder="Enter username"
//                                         value={username}
//                                         onChange={(e) => setUsername(e.target.value)}
//                                     />
//                                 </div>

//                                 <div style={{ height: "4rem" }}></div>
//                                 <button
//                                     style={{ marginTop: "29px" }}
//                                     className="loginBtn"
//                                     onClick={ResetPassword}
//                                 >
//                                     CHANGE PASSWORD
//                                 </button>
//                                 {error && <p className="form_text">{error}</p>}
//                                 <div style={{ height: "4rem" }}></div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 <div className="login_copy_right_container">
//                     <Copyright />
//                 </div>
//             </div>
//         </>
//     );
// }

// export default ChangePassword;



import React, { useState, useEffect } from "react";
import Copyright from "../../commonComponet/copyright";
import Header from "../../commonComponet/header";
import { baseurl } from "../../commonComponet/apibaseurl";
import { Button, Modal, Space } from 'antd';

function ChangePassword() {
    const [username, setUsername] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");


    const [open, setOpen] = useState(false);

    
    const showModal = () => {
        setOpen(true);
      };

      const handleCancel = () => {
        setOpen(false);
        window.location.href="/";
      };

    useEffect(() => {
        setUsername("");
    }, []);

    const ResetPassword = async () => {
        try {
            const response = await fetch(`${baseurl}/api/forgot-password2/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username }),
            });
            const data = await response.json();
            console.log(data.message);
            if (data.success === "1") {
                // localStorage.setItem("successMessage", data.message);
                setSuccessMessage(data.message)
                showModal();
                // window.location.href = "/reset-password";
            } else {
                if (data.success === "4" || data.success === "5") {
                    window.location.href = "/";
                } else {
                    setError(data.message);
                }
            }
        } catch (error) {
            setError("An error occurred. Please try again later.");
        }
    };

    return (
        <>
            <div className="login_page_container">
            <div className="header_container">
                    <nav className="header_nav">
                        <div className="menu_container">
                            <div className="logo_container"></div>
                        </div>
                    </nav>
                </div>
                
                <section>
                    <div className="login_section login_section_mobile">
                        <div className="login_sidebar"></div>
                        <div className="login_form_container login_form_container_mobile">
                            <div className="inner_loginform_container inner_loginform_container_mobile">
                                <div className="title" style={{lineHeight:"1rem"}} >Reset Password</div>
                                <p className="" style={{lineHeight:"2rem", marginBottom:"4rem"}}>A link will be sent to your e-mail in order to complete process</p>
                                {/* {successMessage && (
                                    <p className="form_text success">{successMessage}</p>
                                )} */}

                                <div>
                                    <label htmlFor="">Email</label>
                                    <input
                                        className="inpufeild"
                                        type="text"
                                        placeholder="Enter registered email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>

                                <div style={{ height: "4rem" }}></div>
                                <button
                                    style={{ marginTop: "29px" }}
                                    className="loginBtn"
                                    onClick={ResetPassword}
                                >
                                    RESET
                                </button>
                                {error && <p className="form_text">{error}</p>}
                                <div style={{ height: "4rem" }}></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="login_copy_right_container">
                    <Copyright />
                </div>
            </div>





            <Modal
        open={open}
        // title="Alert"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
          </>
        )}
      >
        <div style={{cursor:"pointer", color:"#000", fontWeight:"500", float:"right", marginTop:"-1rem", marginRight:"-1rem", fontSize:"1.2rem"}} onClick={handleCancel} >X</div>
        <p style={{textAlign:"center", color:"#000", fontSize:"1rem", fontWeight:"600", paddingTop:"2rem"}} >{successMessage}</p>
        <br />
        <div style={{height:"100px", width:"100px", margin:"auto", display:"flex", flexDirection:"column", alignItems:"center"}} >
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" style={{color:"#C6222F"}} width="3rem" height="3rem" viewBox="0 0 20 20"><path fill="currentColor" d="M10 20a10 10 0 0 1 0-20a10 10 0 1 1 0 20m-2-5l9-8.5L15.5 5L8 12L4.5 8.5L3 10z"/></svg>
        </div>
        <h3>Success</h3>
        </div>
      </Modal>


        </>
    );
}

export default ChangePassword;
