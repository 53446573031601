// import React, { useEffect, useState } from "react";

// function Submit({ value, onClick, setFormData, showerror,phase1statusValue,submitfunctionNext }) {
//     const { heard_from } = value;
//     const [isConfirmed, setIsConfirmed] = useState(false);
//     const [isPrivateConfirmed, setIsPrivateConfirmed] = useState(false);

    

//     const handleStepChangeback = (e) => {
//         e.preventDefault();
//         onClick("yourphotograph");
//     };

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value,
//         }));
//     };

//     useEffect(()=>{
//         phase1statusValue("Completed");
//     },[])

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (!isConfirmed || !isPrivateConfirmed) {
//             alert("Please confirm all required checkboxes before submitting.");
//             return;
//         }
//         // onsubmit(e);
//         submitfunctionNext();
//     };

//     return (
//         <>
//             <div className="inner_loginform_container">
//                 <div className="title">Submit</div>

//                 <div style={{ marginTop: "1.8rem" }}>
//                     <label htmlFor="password">How Did you Hear About Us*</label>
//                     <input
//                         className="inpufeild"
//                         type="text"
//                         id="heard_from"
//                         name="heard_from"
//                         value={heard_from}
//                         onChange={handleChange}
//                         placeholder="Please elaborate"
//                     />
//                 </div>

//                 <div style={{ marginTop: "1.8rem" }}>
//                     <div className="confirmation_text_container">
//                         <input
//                             type="checkbox"
//                             id="checkbox"
//                             name="checkbox"
//                             checked={isConfirmed}
//                             onChange={(e) => setIsConfirmed(e.target.checked)}
//                         />
//                         <label htmlFor="checkbox">I Confirm That The Information Provided Is True and Accurate To The Best of My Knowledge</label>
//                     </div>
//                 </div>

//                 <div style={{ marginTop: "1.8rem" }}>
//                     <div className="confirmation_text_container">
//                         <input
//                             type="checkbox"
//                             id="checkbox2"
//                             name="checkbox2"
//                             checked={isPrivateConfirmed}
//                             onChange={(e) => setIsPrivateConfirmed(e.target.checked)}
//                         />
//                         <label htmlFor="checkbox2">
//                             The information you share here is private and confidential. Matchbox will not be sharing any of your personal information outside of Matchbox. It will be shared internally only for the purposes of matchmaking.
//                         </label>
//                     </div>
//                 </div>

//                 <div className="btn_container">
//                     <button onClick={handleStepChangeback} className="loginBtnBack" type="submit">
//                         Back
//                     </button>

//                     <button onClick={handleSubmit} className="loginBtn" type="submit">
//                         Submit
//                     </button>
//                 </div>

//                 <br /><br />
//             </div>
//         </>
//     );
// }

// export default Submit;




// import React, { useEffect, useState, useRef } from "react";
// import { message } from 'antd';

// function Submit({ value, onClick, setFormData, phase1statusValue, submitfunctionNext }) {
//     const { heard_from } = value;
//     const [isConfirmed, setIsConfirmed] = useState(false);
//     const [isPrivateConfirmed, setIsPrivateConfirmed] = useState(false);
//     const [messageApi, contextHolder] = message.useMessage();
//     const heardFromRef = useRef(null);

//     const handleStepChangeback = (e) => {
//         e.preventDefault();
//         onClick("yourphotograph");
//     };

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value,
//         }));
//     };

//     // useEffect(() => {
//     //     phase1statusValue("Completed");
//     // }, []);

//     const handleSubmit = (e) => {
        
       
//         if (heardFromRef.current.value.trim() === "") {
//             messageApi.open({ type: 'error', content: 'How did you hear about us is required!' });
//             return;
//         }
//         if (!isConfirmed || !isPrivateConfirmed) {
//             messageApi.open({ type: 'error', content: 'Please confirm all required checkboxes before submitting.' });
//             return;
//         }
//         // phase1statusValue("Completed");
//         e.preventDefault();
//         submitfunctionNext("Completed");
//     };

//     return (
//         <>
//             {contextHolder}
//             <div className="inner_loginform_container">
//                 <div className="title">Submit</div>

//                 <div style={{ marginTop: "1.8rem" }}>
//                     <label htmlFor="password">How Did you Hear About Us*</label>
//                     <input
//                         className="inpufeild"
//                         type="text"
//                         id="heard_from"
//                         name="heard_from"
//                         value={heard_from}
//                         ref={heardFromRef}
//                         onChange={handleChange}
//                         placeholder="Please elaborate"
//                         maxLength="100"
//                     />
//                 </div>

//                 <div style={{ marginTop: "1.8rem" }}>
//                     <div className="confirmation_text_container">
//                         <input
//                             type="checkbox"
//                             id="checkbox"
//                             name="checkbox"
//                             checked={isConfirmed}
//                             onChange={(e) => setIsConfirmed(e.target.checked)}
//                         />
//                         <label htmlFor="checkbox">I Confirm That The Information Provided Is True and Accurate To The Best of My Knowledge</label>
//                     </div>
//                 </div>

//                 <div style={{ marginTop: "1.8rem" }}>
//                     <div className="confirmation_text_container">
//                         <input
//                             type="checkbox"
//                             id="checkbox2"
//                             name="checkbox2"
//                             checked={isPrivateConfirmed}
//                             onChange={(e) => setIsPrivateConfirmed(e.target.checked)}
//                         />
//                         <label htmlFor="checkbox2">
//                             The information you share here is private and confidential. Matchbox will not be sharing any of your personal information outside of Matchbox. It will be shared internally only for the purposes of matchmaking.
//                         </label>
//                     </div>
//                 </div>

//                 <div className="btn_container">
//                     <button onClick={handleStepChangeback} className="loginBtnBack" type="submit">
//                         Back
//                     </button>

//                     <button onClick={handleSubmit} className="loginBtn" type="submit">
//                         Submit
//                     </button>
//                 </div>

//                 <br /><br />
//             </div>
//         </>
//     );
// }export default Submit;



// import React, { useState, useRef } from "react";
// import { message } from 'antd';

// function Submit({ value, onClick, setFormData, phase1statusValue, submitfunctionNext }) {
//     const { heard_from } = value;
//     const [isConfirmed, setIsConfirmed] = useState(false);
//     const [isPrivateConfirmed, setIsPrivateConfirmed] = useState(false);
//     const [messageApi, contextHolder] = message.useMessage();
//     const heardFromRef = useRef(null);

//     const handleStepChangeback = (e) => {
//         e.preventDefault();
//         onClick("yourphotograph");
//     };

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value,
//         }));
//     };



//     const handleSubmit = (e) => {
//         e.preventDefault();

//         if (heardFromRef.current.value.trim() === "") {
//             messageApi.open({ type: 'error', content: 'How did you hear about us is required!' });
//             return;
//         }
//         if (!isConfirmed || !isPrivateConfirmed) {
//             messageApi.open({ type: 'error', content: 'Please confirm all required checkboxes before submitting.' });
//             return;
//         }

//         submitfunctionNext();
//     };
    
//     const handlePrivateConfirmChange = (e) => {
//         setIsPrivateConfirmed(e.target.checked);
//         if (e.target.checked) {
//             // phase1statusValue("Completed");
//             phase1statusValue(e.target.checked ? "Completed" : "Pending");
//         }
//     };

//     return (
//         <>
//             {contextHolder}
//             <div className="inner_loginform_container">
//                 <div className="title">Submit</div>

//                 <div style={{ marginTop: "1.8rem" }}>
//                     <label htmlFor="heard_from">How Did you Hear About Us*</label>
//                     <input
//                         className="inpufeild"
//                         type="text"
//                         id="heard_from"
//                         name="heard_from"
//                         value={heard_from}
//                         ref={heardFromRef}
//                         onChange={handleChange}
//                         placeholder="Please elaborate"
//                         maxLength="100"
//                     />
//                 </div>

//                 <div style={{ marginTop: "1.8rem" }}>
//                     <div className="confirmation_text_container">
//                         <input
//                             type="checkbox"
//                             id="checkbox"
//                             name="checkbox"
//                             checked={isConfirmed}
//                             onChange={(e) => setIsConfirmed(e.target.checked)}
//                         />
//                         <label htmlFor="checkbox">I Confirm That The Information Provided Is True and Accurate To The Best of My Knowledge</label>
//                     </div>
//                 </div>

//                 <div style={{ marginTop: "1.8rem" }}>
//                     <div className="confirmation_text_container">
//                         <input
//                             type="checkbox"
//                             id="checkbox2"
//                             name="checkbox2"
//                             checked={isPrivateConfirmed}
//                             onChange={handlePrivateConfirmChange}
//                         />
//                         <label htmlFor="checkbox2">
//                             The information you share here is private and confidential. Matchbox will not be sharing any of your personal information outside of Matchbox. It will be shared internally only for the purposes of matchmaking.
//                         </label>
//                     </div>
//                 </div>

//                 <div className="btn_container">
//                     <button onClick={handleStepChangeback} className="loginBtnBack" type="button">
//                         Back
//                     </button>

//                     <button onClick={handleSubmit} className="loginBtn" type="submit">
//                         Submit
//                     </button>
//                 </div>

//                 <br /><br />
//             </div>
//         </>
//     );
// }

// export default Submit;





import React, { useState, useRef, useEffect } from "react";
import { message } from 'antd';

function Submit({ value, onClick, setFormData, phase1statusValue, submitfunctionNext }) {
    const { heard_from } = value;
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isPrivateConfirmed, setIsPrivateConfirmed] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const heardFromRef = useRef(null);



useEffect(()=>{
    phase1statusValue("pending");
},[])


    const handleStepChangeback = (e) => {
        e.preventDefault();
        phase1statusValue("pending");
        onClick("yourphotograph");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const furtherCommentsValue = heardFromRef.current.value.trim();

        if (heardFromRef.current.value.trim() === "") {
            messageApi.open({ type: 'error', content: 'How did you hear about us is required!' });
            return;
        }
        if (!isConfirmed || !isPrivateConfirmed) {
            messageApi.open({ type: 'error', content: 'Please confirm all required checkboxes before submitting.' });
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            heard_from: furtherCommentsValue.charAt(0).toUpperCase() + furtherCommentsValue.slice(1),
        }));
        submitfunctionNext();
    };
    
    const handlePrivateConfirmChange = (e) => {
        const isChecked = e.target.checked;
        setIsPrivateConfirmed(isChecked);
        // setIsConfirmed(isChecked);
        phase1statusValue(isChecked ? "Completed" : "Pending");
    };

    return (
        <>
            {contextHolder}
            <div className="inner_loginform_container">
                <div className="title">Submit</div>

                <div style={{ marginTop: "1.8rem" }}>
                    <label htmlFor="heard_from">How Did you Hear About Us*</label>
                    <input
                        className="inpufeild"
                        type="text"
                        id="heard_from"
                        name="heard_from"
                        value={heard_from}
                        ref={heardFromRef}
                        onChange={handleChange}
                        placeholder="Please elaborate"
                        maxLength="100"
                    />
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <div className="confirmation_text_container">
                        <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            checked={isConfirmed}
                            onChange={(e) => setIsConfirmed(e.target.checked)}
                        />
                        <label htmlFor="checkbox">I Confirm That The Information Provided Is True and Accurate To The Best of My Knowledge</label>
                    </div>
                </div>

                <div style={{ marginTop: "1.8rem" }}>
                    <div className="confirmation_text_container">
                        <input
                            type="checkbox"
                            id="checkbox2"
                            name="checkbox2"
                            checked={isPrivateConfirmed}
                            onChange={handlePrivateConfirmChange}
                        />
                        <label htmlFor="checkbox2">
                            The information you share here is private and confidential. Matchbox will not be sharing any of your personal information outside of Matchbox. It will be shared internally only for the purposes of matchmaking.
                        </label>
                    </div>
                </div>

                <div className="btn_container">
                    <button onClick={handleStepChangeback} className="loginBtnBack" type="button">
                        Back
                    </button>

                    <button onClick={handleSubmit} className="loginBtn" type="submit">
                        Submit
                    </button>
                </div>

                <br /><br />
            </div>
        </>
    );
}

export default Submit;
