import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
// import Header from './commonComponet/header';
// import LoginScreen from './loginScreen';

function App() {
  return (
    <Router>
      <Routes/>
  </Router>
  );
}

export default App;
