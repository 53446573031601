import React, { createContext, useState, useContext } from 'react';

const ImageContext = createContext();

export const useImageContext = () => useContext(ImageContext);

export const ImageProvider = ({ children }) => {
  const [livePhoto, setLivePhoto] = useState(null);
  const [brouseImages, setBrouseImages] = useState([]);

  return (
    <ImageContext.Provider value={{ livePhoto, setLivePhoto, brouseImages, setBrouseImages }}>
      {children}
    </ImageContext.Provider>
  );
};
