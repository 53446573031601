import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Family from "../formsteps/family";
import Step2 from "../formsteps/step2";
import Professional from "../formsteps/Professional";
import Moreaboutyou from "../formsteps/moreaboutyou";
import Yourphotograph from "../formsteps/yourphotograph";
import Submit from "../formsteps/submit";
import Header from "../commonComponet/header";
import Copyright from "../commonComponet/copyright";
import PersonalInformation from "../formsteps/perssonalIformation";
import { baseurl } from "../commonComponet/apibaseurl";
import axios from "axios";
import Header2 from "../commonComponet/header2";

function MultiStepform() {
  const [currentStep, setCurrentStep] = useState("personal");
  const [currentImage, setCurrentImage] = useState({
    personal: "images/click_icon.png",
    step2: "images/unclick_icon.png",
    family: "images/unclick_icon.png",
    professional: "images/unclick_icon.png",
    moreaboutyou: "images/unclick_icon.png",
    yourphotograph: "images/unclick_icon.png",
    submit: "images/unclick_icon.png",
    test: "images/unclick_icon.png",
  });
  const [error, setError] = useState("");
  const [isData, setIsdata] = useState(null);
  const [isDataLivePhoto, setDataLivePhoto] = useState("");
  const [isDataOtherPhoto, setDataOtherPhoto] = useState("");
  const [isphase1statusComplete, setPhase1statusComplete] = useState("");
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState();

 function getDateofbirth(e){
   console.log("getDateofbirth",e)
  }


console.log("isphase1statusComplete",isphase1statusComplete)
if(isphase1statusComplete==="Completed"){
  window.location.href = "/profile"
}

  function testing1(photo){
     setDataLivePhoto(photo)
  }

  function refreshOtherimages(e){
    setDataOtherPhoto(e)
  }


  const handleStepChange = (step) => {
    setCurrentStep(step);
    setCurrentImage((prevImage) => ({
     ...prevImage,
     [step]: "images/click_icon.png",
      [currentStep]: "images/unclick_icon.png",
    }));

   var dividers = document.querySelectorAll(".outer_divider div");
   let stepFound = false;
   dividers.forEach((divider) => {
     const dividerId = divider.id.replace("Divider", "");
     if (dividerId === step) {
       divider.style.width = "1px";
       stepFound = true;
     } else if (!stepFound) {
       divider.style.width = "3px";
     } else {
       divider.style.width = "1px";
     }
   });
   if (step === "personal") {
     dividers.forEach((divider) => {
       divider.style.width = "1px";
     });
   }
  };


  const username = localStorage.getItem("username");

  function updatLivePhotoPropsForm(e){
   setFormData({...formData, live_photo: e===""?null:e });
  }

  function uptBrouseImageForm(e){
    setFormData({...formData, other_images: e });
  }
  function totalAge(e){
    setFormData({...formData, total_age: e }); 
    console.log("testing")
  }
  function phase1status(e){
    setFormData({ ...formData, phase_1_status: e }); 
    console.log("phase_1_status",e);
}

 const [formData, setFormData] = useState({
   first_name: "",
   last_name: "",
   mobile_number: "",
   email: username || "",
   date_of_birth: "",
   gender: "",
   address_line_1: "",
   address_line_2: "",
   city: "",
   country: "",
   postal_code: "",
   father_name: "",
   mother_name: "",
   sibling_count: "",
   education: "",
    education2: "",
    current_profession: "",
    company: "",
    designation: "",
    other_professional_details: "",
    height_feet: "",
    height_inches: "",
    religion: "",
    relationship_status: "",
    further_comments: "",
    heard_from: "",
    live_photo: null,
    other_images: [],
    phase_1_status: "",
    country_code:"",
    total_age:""
  });

 useEffect(() => {
   const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      return;
     }

     try {
       const response = await axios.get(`${baseurl}/api/customer-profile2/`, {
         headers: {
           "Content-Type": "application/json",
           Authorization: `Token ${token}`,
         },
       });
       const data = response.data;
       if (data.success === "4") {
         window.location.href = "/";
       }
       if (data.success === "1") {
         setIsdata(data);
         setDataLivePhoto(data.data.live_photo);
         setDataOtherPhoto(data.data.other_images);
         setPhase1statusComplete(data.phase_1_status);
        //  setDataOtherPhoto(data.);
       }
     } catch (error) {
       console.error("Error fetching user data:", error);
     }
   };

    fetchData();
  }, []);

  useEffect(()=>{
   if(isData && isData.data){
    // console.log(isData.data);
   }
  },[])


  // console.log("isData",isData);

  useEffect(() => {
    if (isData && isData.data) {
      setFormData({
        first_name: isData.data.first_name || "",
        last_name: isData.data.last_name || "",
        mobile_number: isData.data.mobile_number || "",
        email: isData.data.email || username || "",
        date_of_birth: isData.data.date_of_birth || "",
        gender: isData.data.gender || "",
        address_line_1: isData.data.address_line_1 || "",
        address_line_2: isData.data.address_line_2 || "",
        city: isData.data.city || "",
        country: isData.data.country || "",
        postal_code: isData.data.postal_code || "",
        father_name: isData.data.father_name || "",
        mother_name: isData.data.mother_name || "",
        sibling_count: isData.data.sibling_count || "",
        education: isData.data.education || "",
        education2: isData.data.education2 || "",
        current_profession: isData.data.current_profession || "",
        company: isData.data.company || "",
        designation: isData.data.designation || "",
        other_professional_details: isData.data.other_professional_details || "",
        height_feet: isData.data.height_feet || "",
        height_inches: isData.data.height_inches || "",
        religion: isData.data.religion || "",
        relationship_status: isData.data.relationship_status || "",
        further_comments: isData.data.further_comments || "",
        heard_from: isData.data.heard_from || "",
        live_photo:isData.data.live_photo || "",
        other_images:isData.data.other_images || [],
        country_code:isData.data.country_code || "",
        // phase_1_status:isData.data.phase_1_status || "",
        // total_age: isData.data.total_age ||""
      });
    }
  }, [isData, username]);

  console.log("Form Data:", formData);


  const submitfunction = async (e) => {
    // console.log("priti",e)
    // phase1status(e)
    const token = localStorage.getItem("token");
    if (!token) {
     console.error("Token not found in localStorage");
      return;
    }

   try {
     const response = await fetch(`${baseurl}/api/customer-registration/`, {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
         Authorization: `Token ${token}`,
       },
       body: JSON.stringify(formData),
     });
     const data = await response.json();
     console.log(data, 'dt');

   if(data.success ==='1'){
    if (data.phase_1_status==="Completed") {
      console.log(data.phase_1_status)
      // phase1status("Completed");
      window.location.href = "/profile";
    }
   } else {
       if (data.message === "Invalid token") {
         window.location.href = "/";
       } else {
         setError(data);
       }
     }
   } catch (error) {
     setError("An error occurred. Please try again later.");
   }
  };

// const submitfunction = async (status) => {
//   console.log("status",)
//   setFormData((prevData) => ({ ...prevData, phase_1_status: status }));

//   const token = localStorage.getItem("token");
//   if (!token) {
//     console.error("Token not found in localStorage");
//     return;
//   }

//   try {
//     const response = await fetch(`${baseurl}/api/customer-registration/`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Token ${token}`,
//       },
//       body: JSON.stringify(formData),
//     });

//     const data = await response.json();
//     if (response.ok) {
//       console.log("Data posted successfully", data);
//     } else {
//       console.error("Error posting data", data);
//     }
//   } catch (error) {
//     console.error("Network error:", error);
//   }
// };


 return (
   <>
     <div className='custom_header_container'>
      <Header2/>
    </div>
    <section>
      <div className="login_section">
          <div className="login_sidebar_multiform">
           <div className="inner_login_sidebar" style={{ display: "flex", flexDirection: "column", gap: "0" }}>
             <div className="navigation"><img className="images_icon" src={currentImage.personal} alt="login" /><p>Personal</p></div>
             <div className="outer_divider"><div className="personal_divider" id="personalDivide"></div></div>
             <div className="navigation" > <img className="images_icon" src={currentImage.step2} alt="login" /><p>Residential</p></div>
             <div className="outer_divider"><div className="step2_divider" id="step2Divider"></div></div>
             <div className="navigation" ><img className="images_icon" src={currentImage.family} alt="login" /><p>Family</p></div>
             <div className="outer_divider"><div className="family_divider" id="familyDivider"></div></div>
             <div className="navigation"><img className="images_icon" src={currentImage.professional} alt="login" /><p>Professional</p></div>
             <div className="outer_divider"><div className="professional_divider" id="professionalDivider"></div></div>
            <div className="navigation" ><img className="images_icon" src={currentImage.moreaboutyou} alt="login" /><p>More About You</p></div>
            <div className="outer_divider"><div className="moreaboutyou_divider" id="moreaboutyouDivider"></div></div>
            <div className="navigation" ><img className="images_icon" src={currentImage.yourphotograph} alt="login" /><p>Your Photograph</p></div>
            <div className="outer_divider"><div className="yourphotograph_divider" id="yourphotographDivider"></div></div>
            <div className="navigation" ><img className="images_icon" src={currentImage.submit} alt="login" /><p>Submit</p></div>
            <div style={{height:"60px"}}></div>
            </div>
          </div>

         <div className="login_form_container">
           {currentStep === "personal" && (<PersonalInformation value={formData} setFormData={setFormData} onClick={handleStepChange} phase1statusValue={phase1status} submitfunctionNext={submitfunction} totalAgefunction={totalAge} getDateofbirth={getDateofbirth} />)}
          {currentStep === "step2" && <Step2 value={formData} setFormData={setFormData} onClick={handleStepChange} phase1statusValue={phase1status} submitfunctionNext={submitfunction} />}
           {currentStep === "family" && <Family value={formData} setFormData={setFormData} onClick={handleStepChange} phase1statusValue={phase1status} submitfunctionNext={submitfunction} />}
           {currentStep === "professional" && <Professional value={formData} setFormData={setFormData} onClick={handleStepChange} phase1statusValue={phase1status} submitfunctionNext={submitfunction}  />}
           {currentStep === "moreaboutyou" && <Moreaboutyou value={formData} setFormData={setFormData} onClick={handleStepChange} phase1statusValue={phase1status} submitfunctionNext={submitfunction} />}
          {currentStep === "yourphotograph" && <Yourphotograph uptBrouseImageFormProps={uptBrouseImageForm} updatLivePhotoPropsForms={updatLivePhotoPropsForm} value={formData} onClick={handleStepChange} phase1statusValue={phase1status} submitfunctionNext={submitfunction} isDataLivePhotoProps={isDataLivePhoto} testing1={testing1} refreshOtherimages={refreshOtherimages} isDataOtherPhotoprops={isDataOtherPhoto} />}
          {currentStep === "submit" && <Submit value={formData} setFormData={setFormData} onClick={handleStepChange} showerror={error} phase1statusValue={phase1status} submitfunctionNext={submitfunction} />}
         </div>
       </div>
    </section>
     <Copyright/>
   </>
  );
}

export default MultiStepform;