// import React, { useState, useRef, useEffect } from 'react';
// import { useImageContext } from '../formsteps/imageProvider';

// function BrousePhoto({ setbrouseimage, uptBrouseImageProps }) {
//   const { brouseImages, setBrouseImages } = useImageContext();
//   const [images, setImages] = useState(brouseImages);
//   const inputRef = useRef(null);

//   useEffect(() => {
//     setBrouseImages(images);
//   }, [images, setBrouseImages]);

//   const captureImage = () => {
//     inputRef.current.click();
//   };

//   const handleImageChange = (e) => {
//     const files = e.target.files;
//     if (files.length > 0) {
//       const updatedImages = [];
//       let filesProcessed = 0;

//       for (let i = 0; i < files.length; i++) {
//         const file = files[i];
//         const fileExtension = file.name.split('.').pop().toLowerCase();

//         if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
//           const reader = new FileReader();
//           reader.onload = () => {
//             updatedImages.push(reader.result);
//             filesProcessed++;
//             if (filesProcessed === files.length) {
//               const newImages = [...images, ...updatedImages].slice(0, 3);
//               setImages(newImages);
//               setbrouseimage(newImages);
//               uptBrouseImageProps(newImages);
//             }
//           };
//           reader.readAsDataURL(file);
//         } else {
//           filesProcessed++;
//           if (filesProcessed === files.length) {
//             const newImages = [...images, ...updatedImages].slice(0, 3);
//             setImages(newImages);
//             setbrouseimage(newImages);
//           }
//         }
//       }
//     }
//   };

//   return (
//     <>
//       <br />
//       <div style={{ width: '70%', margin: 'auto', display: 'flex', gap: '10px', justifyContent: 'start', alignItems: 'center' }}>
//         {images.map((image, index) => (
//           <img key={index} className='captured' src={image} alt={`Captured ${index + 1}`} width="130" height="130" />
//         ))}
//         <div>
//           {images.length < 3 && (
//             <button className='camerastartCapture_btn' onClick={captureImage}>
//               <div className='camerastartCapture' style={{ backgroundImage: "url(/images/add_icon.png)" }}></div>
//             </button>
//           )}
//         </div>
//       </div>

//       <input
//         type="file"
//         accept=".png, .jpg, .jpeg"
//         capture="environment"
//         multiple
//         ref={inputRef}
//         style={{ display: 'none' }}
//         onChange={handleImageChange}
//       />
//     </>
//   );
// }

// export default BrousePhoto;


import React, { useState, useRef, useEffect } from 'react';
import { useImageContext } from '../formsteps/imageProvider';

function BrousePhoto({ setbrouseimage, uptBrouseImageProps,isDataOtherPhotoprops,refreshOtherimagesprops }) {
  const { brouseImages, setBrouseImages } = useImageContext();
  const [images, setImages] = useState(isDataOtherPhotoprops);
  const inputRef = useRef(null);
  // console.log("isDataOtherPhotoprops",isDataOtherPhotoprops)

  useEffect(() => {
    setBrouseImages(images);
  }, [images, setBrouseImages]);

  const captureImage = () => {
    inputRef.current.click();
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const updatedImages = [];
      let filesProcessed = 0;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
          const reader = new FileReader();
          reader.onload = () => {
            updatedImages.push(reader.result);
            filesProcessed++;
            if (filesProcessed === files.length) {
              const newImages = [...images, ...updatedImages].slice(0, 3);
              setImages(newImages);
              setbrouseimage(newImages);
              uptBrouseImageProps(newImages);
              refreshOtherimagesprops(newImages);
            }
          };
          reader.readAsDataURL(file);
        } else {
          filesProcessed++;
          if (filesProcessed === files.length) {
            const newImages = [...images, ...updatedImages].slice(0, 3);
            setImages(newImages);
            setbrouseimage(newImages);
          }
        }
      }
    }
  };

  const handleDelete = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    setbrouseimage(newImages);
    uptBrouseImageProps(newImages);
  };


  return (
    <>
      <br />
      <div className='brouse_container_image'>
        {images.map((image, index) => (
          <div key={index} style={{ position: 'relative', }}>
            <div style={{width:"150px",height:"150px"}} >
                <img className='captured' src={image} alt={`Captured ${index + 1}`} width="100%" height="100%" style={{objectFit:"contain"}} />
            </div> 
            <button 
              onClick={() => handleDelete(index)} 
              style={{ position: 'absolute', top: 0, right: 0, background: 'red', color: 'white', border: 'none', borderRadius: '50%', height:"1.5rem", width:"1.5rem" }}>
              X
            </button>
          </div>
        ))}
        <div>
          {images.length < 3 && (
            <button className='camerastartCapture_btn' onClick={captureImage}>
              <div className='camerastartCapture' style={{ backgroundImage: "url(/images/add_icon.png)" }}></div>
            </button>
          )}
        </div>
      </div>

      <input
        type="file"
        accept=".png, .jpg, .jpeg"
        // capture="environment"
        multiple
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />



      {/* <input type="file" /> */}
    </>
  );
}

export default BrousePhoto;
