import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { baseurl } from "../../commonComponet/apibaseurl";

function KycDocumentsComp() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null); 
  const [isData, setIsData] = useState([]);
  const [messegesdata, setMessegesdata] = useState(null);
  const fileInputRef = useRef(null); // Create a ref for the file input

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const Addkycfunction = async () => {
    const token = localStorage.getItem("token");
    console.log(token);

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    if (selectedFile) {
      const formData = new FormData();
      formData.append('document_file', selectedFile);

      try {
        const response = await axios.post(`${baseurl}/api/add-kyc-document/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Token ${token}`
          },
        });
        console.log('File uploaded successfully', response.data);
        if(response.data.success==='4'){
          window.location.href = "/"
        }
        if(response.data.message==="Documents uploaded successfully"){
          fetchData();
        }
      } catch (error) {
        console.error('Error uploading file', error);
      } finally {
        // Clear the file input field
        setSelectedFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      }
    } else {
      alert('Please select a file first!');
    }
  };

  function fetchData() {
    const token = localStorage.getItem("token");
    console.log(token);

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    fetch(`${baseurl}/api/list-kyc-documents/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if(data.success==='4'){
          window.location.href = "/"
        }
        if(data.success==='1'){
          setIsData(data.data);
          setMessegesdata(data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="kyc_view_page" style={{ paddingTop: "0.5rem" }}>
        <h5 style={{ fontWeight: "500", textTransform: "uppercase" }}>Upload Document</h5>
        {messegesdata === null ? (
          console.log("loading")
        ) : (
          <h4 style={{ color: "#000" }}>{messegesdata.kyc_status_message}</h4>
        )}
        <br />
        {isData === null ? (
          console.log("loading")
        ) : (
          isData.map((items, index) => {
            const documentUrl = items.document_files.map((item) => item.document_file)[0];
            const documentName = documentUrl.split('/').pop();
            const documentExtention = documentUrl.split('.').pop();

            console.log(documentExtention)
            return (
              <div key={index} style={{width:"100%"}} >
                <div className="kyc_list_card">
                  <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                    <div className="kyc_images_container">
                      {
                        documentExtention === "pdf" ?
                          <iframe className="pdf-viewer"  src={documentUrl} width="100%" height="100%"></iframe>
                          :
                          <img src={documentUrl} alt="document" height="100%" width="100%" style={{objectFit:"contain"}} />
                      }
                    </div>
                    <h4 className="mobile_document_name" >{documentName}</h4>
                  </div>
                  <button
                    className="profile_view_button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                    onClick={
                      () => setSelectedDocument(documentUrl)
                    }
                  >
                    View
                  </button>
                </div>
              </div>
            );
          })
        )}

        {/* Modal for viewing the uploaded documents */}
        <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content" >
              <div className="modal-header">
                <h5 style={{ fontWeight: "500", textTransform: "uppercase" }}>Uploaded Document</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {
              selectedDocument &&  
              (selectedDocument.split('.').pop() === "pdf" ?
                <iframe className="pdf-viewer" 
                        src={selectedDocument} 
                        width="100%" 
                        height="400px">
                </iframe>
                :
                <img src={selectedDocument} alt="document" style={{objectFit:"contain", maxHeight:"400px"}} />
              )
              }
            </div>
          </div>
        </div>

        <button className="kyc_upload_button" data-bs-toggle="modal" data-bs-target="#exampleModal">
          Upload Document
        </button>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 style={{ fontWeight: "500", textTransform: "uppercase" }}>Upload Document</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="kyc_card">
                <h6>Upload file only in png, jpeg, jpg and Pdf format</h6>
                <div className="kyc_card_inputfield">
                  <br />
                  <input 
                    type="file" 
                    onChange={handleFileChange} 
                    className="choose_file_button" 
                    accept=".png, .jpg, .jpeg, .pdf" 
                    ref={fileInputRef} // Attach the ref here
                  />
                </div>
                <br /><br />
                <button className="kyc_upload_button" onClick={Addkycfunction} data-bs-dismiss="modal" aria-label="Close">Upload File</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KycDocumentsComp;








// import axios from "axios";
// import React, { useEffect, useRef, useState } from "react";
// import { baseurl } from "../../commonComponet/apibaseurl";
// import * as pdfjsLib from 'pdfjs-dist/webpack';

// pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

// function KycDocumentsComp() {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [selectedDocument, setSelectedDocument] = useState(null); 
//   const [isData, setIsData] = useState([]);
//   const [messegesdata, setMessegesdata] = useState(null);
//   const fileInputRef = useRef(null); // Create a ref for the file input
//   const pdfCanvasRef = useRef(null); // Create a ref for the canvas

//   const handleFileChange = (event) => {
//     setSelectedFile(event.target.files[0]);
//   };

//   const Addkycfunction = async () => {
//     const token = localStorage.getItem("token");
//     console.log(token);

//     if (!token) {
//       console.error("Token not found in localStorage");
//       return;
//     }

//     if (selectedFile) {
//       const formData = new FormData();
//       formData.append('document_file', selectedFile);

//       try {
//         const response = await axios.post(`${baseurl}/api/add-kyc-document/`, formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//             'Authorization': `Token ${token}`
//           },
//         });
//         console.log('File uploaded successfully', response.data);
//         if(response.data.success==='4'){
//           window.location.href = "/"
//         }
//         if(response.data.message==="Documents uploaded successfully"){
//           fetchData();
//         }
//       } catch (error) {
//         console.error('Error uploading file', error);
//       } finally {
//         // Clear the file input field
//         setSelectedFile(null);
//         if (fileInputRef.current) {
//           fileInputRef.current.value = null;
//         }
//       }
//     } else {
//       alert('Please select a file first!');
//     }
//   };

//   function fetchData() {
//     const token = localStorage.getItem("token");
//     console.log(token);

//     if (!token) {
//       console.error("Token not found in localStorage");
//       return;
//     }

//     fetch(`${baseurl}/api/list-kyc-documents/`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Token ${token}`
//       },
//     })
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then(data => {
//         if(data.success==='4'){
//           window.location.href = "/"
//         }
//         if(data.success==='1'){
//           setIsData(data.data);
//           setMessegesdata(data);
//         }
//       })
//       .catch(error => {
//         console.error('Error:', error);
//       });
//   }

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const renderPDF = (url) => {
//     const canvas = pdfCanvasRef.current;
//     const ctx = canvas.getContext('2d');

//     pdfjsLib.getDocument(url).promise.then((pdf) => {
//       pdf.getPage(1).then((page) => {
//         const viewport = page.getViewport({ scale: 1.5 });
//         canvas.height = viewport.height;
//         canvas.width = viewport.width;

//         const renderContext = {
//           canvasContext: ctx,
//           viewport: viewport
//         };
//         page.render(renderContext);
//       });
//     });
//   };

//   useEffect(() => {
//     if (selectedDocument && selectedDocument.split('.').pop() === "pdf") {
//       renderPDF(selectedDocument);
//     }
//   }, [selectedDocument]);

//   return (
//     <>
//       <div className="kyc_view_page" style={{ paddingTop: "0.5rem" }}>
//         <h5 style={{ fontWeight: "500", textTransform: "uppercase" }}>Upload Document</h5>
//         {messegesdata === null ? (
//           console.log("loading")
//         ) : (
//           <h4 style={{ color: "#000" }}>{messegesdata.kyc_status_message}</h4>
//         )}
//         <br />
//         {isData === null ? (
//           console.log("loading")
//         ) : (
//           isData.map((items, index) => {
//             const documentUrl = items.document_files.map((item) => item.document_file)[0];
//             const documentName = documentUrl.split('/').pop();
//             const documentExtention = documentUrl.split('.').pop();

//             console.log(documentExtention)
//             return (
//               <div key={index} style={{width:"100%"}} >
//                 <div className="kyc_list_card">
//                   <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
//                     <div className="kyc_images_container">
//                       {
//                         documentExtention === "pdf" ?
//                           <canvas ref={pdfCanvasRef}></canvas>
//                           :
//                           <img src={documentUrl} alt="document" height="100%" width="100%" style={{objectFit:"contain"}} />
//                       }
//                     </div>
//                     <h4 className="mobile_document_name" >{documentName}</h4>
//                   </div>
//                   <button
//                     className="profile_view_button"
//                     data-bs-toggle="modal"
//                     data-bs-target="#exampleModal2"
//                     onClick={
//                       () => setSelectedDocument(documentUrl)
//                     }
//                   >
//                     View
//                   </button>
//                 </div>
//               </div>
//             );
//           })
//         )}

//         {/* Modal for viewing the uploaded documents */}
//         <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//           <div className="modal-dialog">
//             <div className="modal-content" >
//               <div className="modal-header">
//                 <h5 style={{ fontWeight: "500", textTransform: "uppercase" }}>Uploaded Document</h5>
//                 <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//               </div>
//               {
//               selectedDocument &&  
//               (selectedDocument.split('.').pop() === "pdf" ?
//                 <canvas ref={pdfCanvasRef} ></canvas>
//                 :
//                 <img src={selectedDocument} alt="document" style={{objectFit:"contain", maxHeight:"400px"}} />
//               )
//               }
//             </div>
//           </div>
//         </div>

//         <button className="kyc_upload_button" data-bs-toggle="modal" data-bs-target="#exampleModal">
//           Upload Document
//         </button>

//         <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 style={{ fontWeight: "500", textTransform: "uppercase" }}>Upload Document</h5>
//                 <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//               </div>
//               <div className="kyc_card">
//                 <h6>Upload file only in png, jpeg, jpg and Pdf format</h6>
//                 <div className="kyc_card_inputfield">
//                   <br />
//                   <input 
//                     type="file" 
//                     onChange={handleFileChange} 
//                     className="choose_file_button" 
//                     accept=".png, .jpg, .jpeg, .pdf" 
//                     ref={fileInputRef} // Attach the ref here
//                   />
//                 </div>
//                 <br /><br />
//                 <button className="kyc_upload_button" onClick={Addkycfunction} data-bs-dismiss="modal" aria-label="Close">Upload File</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default KycDocumentsComp;

