import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { baseurl } from "./apibaseurl";
import { Button, Modal } from 'antd';

function Header({ onClickpageNavigate, phase2Status, getSetction, headeranswer,headerisEditAnswer }) {
  // console.log(headerisEditAnswer)
  const [isdropdownhide, setIsdropdownhide] = useState(true);
  const [error, setError] = useState("");
  const [isprofile, setIsprofile] = useState("");

  const [isdatasubmit, setIsDatasubmit] = useState(null);
  const [answer, setAnswer] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [message, setMessege] = useState();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const dropdownRef = useRef(null);
  const dropdownToggleRef = useRef(null);

  const showModal = () => {
    setOpen(true);
  };
  const showModal2 = () => {
    setOpen2(true);
  };
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const dropdownFuntion = () => {
    setIsdropdownhide(prevState => !prevState);
  };

  const handlelogout = async () => {
    const token = localStorage.getItem("token");
    console.log(token);

    try {
      const response = await fetch(`${baseurl}/api/logout/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Token ${token}`
        },
      });
      console.log(response);
      if (response.ok) {
        window.location.href = "/";
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    }
  };

  const submitFunction = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    const payload = {
      answers: headeranswer,
      phase_2_status: "Pending"
    };

    const jsonData = JSON.stringify(payload);
    fetch(`${baseurl}/api/submit-phase-2-answers/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: jsonData
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Success:', data);

        setMessege(data.message);
        if (data.success === '4') {
          window.location.href = "/";
        }
        if (data.success === '1') {
          setSubmissionStatus('success');
          showModal2();
        } else {
          setSubmissionStatus('error');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setSubmissionStatus('error');
      });
  };

  const isLogoutfunction = () => {
    getSetction === "phase2" ? (phase2Status === "Pending" ? (headerisEditAnswer===true?showModal():handlelogout()) : handlelogout()) : handlelogout();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownToggleRef.current &&
        dropdownRef.current &&
        !dropdownToggleRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsdropdownhide(true);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  return (
    <>
      <div className="header_container">
        <nav className="header_nav">
          <div className="menu_container">
            <div className="logo_container"></div>

            <div ref={dropdownToggleRef} onClick={dropdownFuntion} style={{cursor:"pointer"}} >
              {isdropdownhide === true ?
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="currentColor" d="M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z" /></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="currentColor" d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z" /></svg>
              }
            </div>
          </div>
        </nav>

        <div ref={dropdownRef} className="header_dropdown" hidden={isdropdownhide}>
          <NavLink to={isprofile} style={{ textDecoration: "none", color: "#000" }}>
            <div className="dropdown_menu_container" onClick={() => window.location.href === `${window.location.origin}/profile` ? onClickpageNavigate("phase1") : setIsprofile("/profile")} style={{ cursor: "pointer" }}>
              <h6>Home</h6>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" style={{ color: "#C6222F", marginTop: "-0.5rem" }} viewBox="0 0 24 24"><g fill="none"><path stroke="currentColor" d="M21 12a8.958 8.958 0 0 1-1.526 5.016A8.991 8.991 0 0 1 12 21a8.991 8.991 0 0 1-7.474-3.984A9 9 0 1 1 21 12Z" /><path fill="currentColor" d="M13.5 9a1.5 1.5 0 0 1-1.5 1.5v1A2.5 2.5 0 0 0 14.5 9zM12 10.5A1.5 1.5 0 0 1 10.5 9h-1a2.5 2.5 0 0 0 2.5 2.5zM10.5 9A1.5 1.5 0 0 1 12 7.5v-1A2.5 2.5 0 0 0 9.5 9zM12 7.5A1.5 1.5 0 0 1 13.5 9h1A2.5 2.5 0 0 0 12 6.5zM5.166 17.856l-.48-.142l-.077.261l.177.207zm13.668 0l.38.326l.177-.207l-.078-.261zM9 15.5h6v-1H9zm0-1a4.502 4.502 0 0 0-4.313 3.214l.958.285A3.502 3.502 0 0 1 9 15.5zm3 6a8.48 8.48 0 0 1-6.455-2.97l-.759.652A9.48 9.48 0 0 0 12 21.5zm3-5a3.502 3.502 0 0 1 3.355 2.5l.958-.286A4.502 4.502 0 0 0 15 14.5zm3.455 2.03A8.48 8.48 0 0 1 12 20.5v1a9.48 9.48 0 0 0 7.214-3.318z" /></g></svg>
            </div>
          </NavLink>

          <NavLink to="/change-password" style={{ textDecoration: "none", color: "#000" }}>
            <div className="dropdown_menu_container">
              <h6>Change Password</h6>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style={{color:"#C6222F", marginTop:"-0.5rem"}} viewBox="0 0 24 24"><path fill="currentColor" d="M2.5 18.5v-1h19v1zm.535-5.973l-.762-.442l.965-1.693h-1.93v-.884h1.93l-.965-1.642l.762-.443L4 9.066l.966-1.643l.761.443l-.965 1.642h1.93v.884h-1.93l.965 1.693l-.762.442L4 10.835zm8 0l-.762-.442l.966-1.693H9.308v-.884h1.93l-.965-1.642l.762-.443L12 9.066l.966-1.643l.761.443l-.965 1.642h1.93v.884h-1.93l.965 1.693l-.762.442L12 10.835zm8 0l-.762-.442l.966-1.693h-1.931v-.884h1.93l-.965-1.642l.762-.443L20 9.066l.966-1.643l.761.443l-.965 1.642h1.93v.884h-1.93l.965 1.693l-.762.442L20 10.835z"/></svg>
              </div>
          </NavLink>

          <div className="dropdown_menu_container" onClick={isLogoutfunction} >
            <h6 style={{cursor:"pointer"}} >Logout</h6>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style={{color:"#C6222F", marginTop:"-0.5rem"}} viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 6.5C4.159 8.148 3 10.334 3 13a9 9 0 1 0 18 0c0-2.666-1.159-4.852-3-6.5M12 2v9m0-9c-.7 0-2.008 1.994-2.5 2.5M12 2c.7 0 2.008 1.994 2.5 2.5" color="currentColor"/></svg>
            </div>
        </div>
      </div>

      <Modal
        open={open}
        // title="Alert"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={submitFunction} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >Yes</Button>
            <Button onClick={handlelogout} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >No</Button>
            <Button onClick={handleCancel} style={{backgroundColor:"#000",color:"#fff",border:"none"}} >Cancel</Button>
          </>
        )}
      >
        <br />
        <h4>Do you want to save the changes?</h4>
        <br />
      </Modal> 

      <Modal
        open={open2}
        // title="Alert"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={handlelogout} style={{backgroundColor:"#C6222F",color:"#fff",border:"none"}} >Ok</Button>
          </>
        )}
      >
        <br />
        <h4>Changes are saved successfully.</h4>
        <br />
      </Modal> 
    </>
  );
}

export default Header;
